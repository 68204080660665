<template>
  <div>
    <rir-modal ref="modal" />
    <rir-notification ref="notification" />
    <div class="head-line">
      <div class="triline" @click="toggleMenu()">
        <span class="icon icon-triline"></span>
      </div>
      <div class="head-caption amelie--text">Аттестация</div>
    </div>
    <div class="menu" v-bind:class="{ active: menuOpen }">
      <div class="head-caption amelie--text">Аттестация</div>
      <div @click="menuOpen = false">
        <Menu></Menu>
      </div>
      <div class="bottom">
        <div class="logo"></div>
      </div>
    </div>
    <main>
      <CurrentUser />
      <router-view/>
    </main>

  </div>
</template>

<script>
import Menu from "./components/Menu";
import CurrentUser from "./components/CurrentUser";

export default {
  name: 'App',
  mounted () {
    this.$root.$modal = this.$refs.modal.openModal
    this.$root.$notification = this.$refs.notification.pushNotification
  },
  components: {
    Menu,
    CurrentUser
  },
  data () {
    return {
      menuOpen: false,
    }
  },
  methods: {
    toggleMenu () {
      this.menuOpen = !this.menuOpen;
    }
  }
}
</script>

<style lang="scss">
@import "assets/styles.scss";
</style>
