<template>
  <div class="employee-next-attestation">
        <span v-if="!item.editing">
            {{ month }}
        </span>
    <div class="select-month d-flex align-center" v-if="item.editing">
      <rir-select
          class="mr-2"
          style="width: 96px"
          :items="commissionYear(0)"
          v-model="attestationInfo.attestationYear"
      />
      <rir-select
          style="width: 132px"
          :items="commissionMonth"
          v-model="attestationInfo.month"
      />
    </div>
  </div>
</template>

<script>
import {commissionMonth} from "@/models/EmployeeDicts";

export default {
  name: 'EmployeeYearAttestation',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      attestationYear: null,
      commissionMonth: commissionMonth,
      attestationMonth: null,
      initialMonth: null,
      attestationInfo: null,
    }
  },
  mounted () {
    this.attestationInfo = this.item.getYearAttestationInfo(this.attestationYearSelect);
    this.initialMonth = this.attestationInfo.month;
    this.attestationMonth = this.attestationInfo.month;
    this.attestationYear = this.attestationInfo.attestationYear;
  },
  computed: {
    month () {
      return this.commissionMonth.find(el => {
        return el.id === this.attestationMonth;
      })?.value + ' ' + this.attestationYearSelect;
    },
    attestationYearSelect () {
      // @TODO Переделать в будущем на VUEX
      return this.$parent.$parent.$parent.attestation.year;
    },
    commissionYear () {
      return (yearDeviation) => {
        const attestations = this.item.getPrevAttestation()
        const year = (new Date()).getFullYear()
        const yearList = []
        for (let x = yearDeviation; x < 4; x++) {
          yearList.push({
            value: year + x,
            id: year + x,
            disabled: attestations.some(a => a.attestationYearSelect === year + x)
          })
        }
        return yearList
      }
    },
  }
}
</script>

<style scoped lang="scss">
.employee-next-attestation {
  position: relative;

  .select-month {
    padding-bottom: 5px;
  }

  .select-date {
    display: inline-block;
    vert-align: top;
  }

  .save {
    display: block;
    position: absolute;
    left: -35px;
    bottom: 0;
    padding: 10px;
    cursor: pointer;
  }
}
</style>
