<template>
  <div class="dashboard wrap pb-5">
    <h1 class="mb-5">Нормативные документы</h1>
    <rir-tabs
      class="mb-8"
      @input="load"
      :items="attestationList"
      id-value="id"
      text-value="year"
      v-model="attestationId"
    />
    <div class="melia mb-6">Постановления</div>
    <div class="mb-6">
      <div class="light-button full-width" @click="getAttestationDecree()">
        <span class="is-loading" v-if="attestationDecreePending"></span>
        Сформировать постановление
      </div>
    </div>
    <rir-drag-upload-file
      :count-line="3"
      :count-file="0"
      :upload-url="uploadUrl"
      v-model="listDecreeFile"
      :acceptFile="['.pdf', '.odt']"
      :params-body="paramsBodyUploadDecree"
      :params-headers="paramsHeaderUpload"
    />
    <br />
    <div class="melia mb-6">Протоколы</div>
    <div class="row row--no-grep">
      <div class="col-md-4-12">
        <rir-select
          label="Отчетный месяц"
          :items="listOfCommission"
          textValue="month"
          idValue="id"
          v-model="commissionIdForProtocol"
        />
      </div>
      <div class="col-md-8-12">
        <div
          :disabled="!commissionIdForProtocol"
          class="light-button full-width"
          @click="getAttestationProtocol()"
        >
          <span class="is-loading" v-if="attestationProtocolPending"></span>
          Сформировать протокол
        </div>
      </div>
    </div>
    <rir-drag-upload-file
      :count-line="3"
      :count-file="0"
      :upload-url="uploadUrl"
      v-model="listProtocolFile"
      :acceptFile="['.pdf', '.odt']"
      :params-body="paramsBodyUploadProtocol"
      :params-headers="paramsHeaderUpload"
    />
    <br />
    <div class="melia mb-6">Распоряжение</div>
    <div class="row row--no-grep">
      <div class="col-md-4-12">
        <rir-select
          label="Отчетный месяц"
          :items="listOfCommission"
          textValue="month"
          idValue="date"
          v-model="commissionDateForOrder"
        />
      </div>
      <div class="col-md-8-12">
        <div
          :disabled="!commissionDateForOrder"
          class="light-button full-width"
          @click="getAttestationOrder()"
        >
          <span class="is-loading" v-if="attestationOrderPending"></span>
          Сформировать распоряжение
        </div>
      </div>
    </div>
    <rir-drag-upload-file
      class="mb-8"
      :count-line="3"
      :count-file="0"
      :upload-url="uploadUrl"
      v-model="listOrderFile"
      :acceptFile="['.pdf', '.odt']"
      :params-body="paramsBodyUploadOrder"
      :params-headers="paramsHeaderUpload"
    />
    <br />
    <div class="melia mb-6">Аттестационные листы</div>
    <div class="row row--no-grep">
      <div class="col-md-4-12">
        <rir-select
          label="Отчетный месяц"
          :items="listOfCommission"
          textValue="month"
          idValue="id"
          v-model="commissionIdForSheet"
        />
      </div>
      <div class="col-md-8-12">
        <div
          :disabled="!commissionIdForSheet"
          class="light-button full-width"
          @click="getSheet()"
        >
          <span class="is-loading" v-if="sheetPending"></span>
          Сформировать аттестационный лист
        </div>
      </div>
    </div>
    <rir-drag-upload-file
      class="mb-8"
      :count-line="3"
      :count-file="0"
      :upload-url="uploadUrl"
      v-model="listSheetFile"
      :acceptFile="['.pdf', '.odt']"
      :params-body="paramsBodyUploadSheet"
      :params-headers="paramsHeaderUpload"
    />
  </div>
</template>

<script>
import { DocumentApi } from "@/api/DocumentApi";
import { CommissionApi } from "@/api/CommissionApi";
import { ReportApi } from "@/api/ReportApi";
import { AttestationApi } from "@/api/AttestationApi";
import { DateFormatted } from "@/plugins/DateFormatted";
import CONFIG from "@/config";

export default {
  name: "Documents",
  components: {},
  data() {
    return {
      search: null,
      commissionIdForSheet: null,
      commissionIdForProtocol: null,
      commissionDateForOrder: null,
      sheetPending: false,
      attestationDecreePending: false,
      attestationProtocolPending: false,
      attestationOrderPending: false,
      attestationId: 1,
      currentYear: new Date().getFullYear(),
      attestationList: [],
      listOfCommission: [],
      list: [],
    };
  },
  async mounted() {
    const attestationYear = await AttestationApi.getList();
    this.attestationList = attestationYear.sort((a, b) => a.year - b.year);
    this.attestationId = this.attestationList.find(
      (el) => el.year === new Date().getFullYear()
    ).id;
    await this.load();
  },
  computed: {
    paramsHeaderUpload() {
      return {
        Authorization: "Bearer " + this.$keycloak.token,
      };
    },
    uploadUrl() {
      return `${CONFIG.API_URL}/api/v1/document`;
    },
    paramsBodyUploadOrder() {
      return {
        attestationId: this.attestationId,
        type: "ORDER",
      };
    },
    paramsBodyUploadProtocol() {
      return {
        attestationId: this.attestationId,
        type: "PROTOCOL",
      };
    },
    paramsBodyUploadDecree() {
      return {
        attestationId: this.attestationId,
        type: "ATTESTATION_DECREE",
      };
    },
    paramsBodyUploadSheet() {
      return {
        attestationId: this.attestationId,
        type: "CERTIFICATION_SHEET",
      };
    },
    listOrderFile: {
      get() {
        return this.list.filter((el) => el.type === "ORDER");
      },
      set(val) {
        this.list = this.checkFiles(val, "ORDER");
      },
    },
    listDecreeFile: {
      get() {
        return this.list.filter((el) => el.type === "ATTESTATION_DECREE");
      },
      set(val) {
        this.list = this.checkFiles(val, "ATTESTATION_DECREE");
      },
    },
    listSheetFile: {
      get() {
        return this.list.filter((el) => el.type === "CERTIFICATION_SHEET");
      },
      set(val) {
        this.list = this.checkFiles(val, "CERTIFICATION_SHEET");
      },
    },
    listProtocolFile: {
      get() {
        return this.list.filter((el) => el.type === "PROTOCOL");
      },
      set(val) {
        this.list = this.checkFiles(val, "PROTOCOL");
      },
    },
  },
  methods: {
    async load() {
      const files = await DocumentApi.getList(null, this.attestationId);
      this.list = files.map((f) => {
        f.url = `${CONFIG.API_URL}${f.url}`;
        return f;
      });
      this.listOfCommission = []
      this.listOfCommission = await CommissionApi.getByAttestationId(
        this.attestationId
      );
      this.commissionDateForOrder = null
      // LSCLOC-320
      this.listOfCommission.sort((left, right) => {
        return new Date(left.date).getTime() - new Date(right.date).getTime()
      });
      this.listOfCommission = this.listOfCommission.reduce((acc, el) => {
        if (el.date) {
          acc.push({
            ...el,
            month: new DateFormatted(el.date).month(true)
          })
        }
        return acc;
      }, []);
    },
    async getAttestationDecree() {
      this.attestationDecreePending = true;
      await ReportApi.getAttestationDecree(this.currentYear);
      this.attestationDecreePending = false;
    },
    async getSheet() {
      this.sheetPending = true;
      await ReportApi.getCertificationSheet(this.commissionIdForSheet);
      this.sheetPending = false;
    },
    async getAttestationProtocol() {
      if (!this.commissionIdForProtocol) {
        return;
      }
      this.attestationProtocolPending = true;
      await ReportApi.getCommissionProtocol(this.commissionIdForProtocol);
      this.attestationProtocolPending = false;
    },
    async getAttestationOrder() {
      if (!this.commissionDateForOrder) {
        return;
      }
      this.attestationOrderPending = true;
      await ReportApi.getOrderReport(
        new DateFormatted(this.commissionDateForOrder).Ymd()
      );
      this.attestationOrderPending = false;
    },
    remFile(fileId) {
      DocumentApi.delete(fileId);
    },
    checkFiles(arr, type) {
      const files = this.list.filter((el) => el.type === type);
      const filesOther = this.list.filter((el) => el.type !== type);
      if (files.length > arr.length) {
        const fileRem = files.find((f) => {
          return !arr.some((l) => l.id === f.id);
        });
        this.remFile(fileRem.id);
      }
      return [...filesOther, ...this.addHostUrl(arr)]
    },
    addHostUrl(val) {
      const host_regex = /(http|https)/;
      return val.map((v) => {
        if (!host_regex.test(v.url)) {
          v.url = `${CONFIG.API_URL}${v.url}`;
        }
        return v;
      });
    },
  },
};
</script>

<style>
[disabled] {
  /* user-select: none; */
  pointer-events: none;
  opacity: 0.48;
}
</style>
