import RestClient from './RestClient';
import CONFIG from '../config';
import Attestation from "../models/Attestation";

let Client = new RestClient({ baseURL: CONFIG.API_URL });

export const AttestationApi = {
  async getList() {
    try {
      const res = await Client.get('/api/v1/attestation');
      if (res.data) {
        return res.data.map(el => {
          return new Attestation(el);
        });
      } else {
        console.log('res', res);
        return [];
      }
    } catch (e) {
      console.log('error', e);
      return [];
    }
  }
};
