<template>
  <div class="current-account">
    <div class="ico-block d-flex align-center">
      <rir-icon
        icon="exit"
        fill="titanic"
        size="20"
        @click.native="logoutSystem"
      />
    </div>
    <div class="ico-block">
      <div class="ico ava d-flex align-center justify-center alien">
        <rir-icon icon="avatar" fill="amelie" />
      </div>
    </div>
    <div class="username">
      <div class="amphiris titanic--text">
        {{ username }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrentUser",
  data: (vm) => ({
    username: vm.$keycloak.fullName,
  }),
  methods: {
    logoutSystem() {
      this.$keycloak.logoutFn();
    },
  },
};
</script>

<style lang="scss">
</style>
