export default class {
  finishTime = null
  id = null
  name = null
  result = null
  score = null
  startTime = null
  surveyCode = null
  surveyId = null

  constructor (data = {}) {
    for (let key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key]
      }
    }
  }

}
