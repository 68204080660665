export default class {
    month = '';
    yearMonth = '';
    employees = [];
    commission = null;
    defaultDate = null;
    attestationMonth = null;
    passDate = null;
    state = null;
    collapsed = true;

    constructor(data = {}) {
        for (let key in this) {
            if (data[key] !== undefined) {
                if (data[key] !== 'null') {
                    this[key] = data[key];
                } else {
                    this[key] = '';
                }
            }
        }
    }

    checkedEmployees() {
        return this.employees.filter(el => {
            return el.checked;
        });
    }

    selectAllEmployees(event) {
        this.employees = this.employees.map(el => {
            el.checked = event;
            return el;
        });
    }

    canApply() {
        return (this.checkedEmployees().length > 0)
        && this.attestationMonth
    }

    // LSCLOC-356
    get employeesMails () {
      return this.employees.filter(employee => {
        return (employee.email && employee.email.length > 0) || (employee.chiefEmail && employee.chiefEmail.length > 0);
      }).map(employee => {
        let mail = ''
        if (employee.email && employee.email.length > 0) {
          mail += employee.email
        }
        if (employee.chiefEmail && employee.chiefEmail.length > 0)  {
          mail += `;${employee.chiefEmail}`
        }
        return mail
      }).join(';');
    }

    // LSCLOC-356
    get commissionsMails () {
      return this.commission.commissionMembers.filter(employee => {
        return (employee.email && employee.email.length > 0)
      }).map(employee => {
        return employee.email
      }).join(';');
    }

    mailtoEmployees(emailBody = '', group = 'employeesMails') {
        // LSCLOC-356
        let to = this[group]

        let email = {
            subject: "Уведомление",
            body: emailBody
        };

        let outputUrl = "mailto:" + to;
        const emailKeys = Object.keys(email);
        const remaining = emailKeys.filter(
            (key) => email[key].trim().length > 0
        );
        if (remaining.length > 0) {
            outputUrl += "?";
        }
        outputUrl += remaining
            .map((key) => `${key}=${encodeURI(email[key].trim())}`)
            .join("&");
        return outputUrl;
    }
}
