import axios from 'axios'
import Vue from "vue";
/*
function apiStatus (response = {}) {
  if (!response || typeof response === 'string') {
    throw new Error(response)
  }

  const {data, status, statusText, headers} = response

  const statusError = !status || ((status < 200 || status > 300))
  const serviceError = (data && data.error) || (statusError && data && data.code)
  const serviceErrorText = serviceError || data.message
  const errorValue = serviceErrorText || statusText || 'error'
  const initialError = typeof data === 'object' ? {...data} : {}

  if (statusError || serviceError) {
    return {...initialError, error: errorValue, status}
  } else {
    return {data, headers}
  }
}
*/

export default class RestClient {
  constructor (props = {}) {
    this.instance = axios.create({
      baseURL: props.baseURL || ''
    });

    this.instance.interceptors.request.use(async (config = {}) => {
      if (config.params) {
        for (let key in config.params) {
          if (config.params[key] === null || config.params[key] === undefined || config.params[key] === '') {
            delete config.params[key]
          }
        }
      }
      config.headers = {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Vue.prototype.$keycloak.token,
      };
      return config;
    }, (error) => {
      return Promise.reject(error)
    });


    this.instance.interceptors.response.use(
      // (response) => apiStatus(response), ({response} = {}) => apiStatus(response),
      (response) => response,
      (error) => {
        // debugger;
        throw error.response.data;
      }

    )
  }

  getNonEmptyFilter (filter) {
    for (let key in filter) {
      if (filter[key] == null || filter[key].toString().length == 0) {
        delete filter[key];
      }
    }
    return filter;
  }

  async get (url, options = {}) {
    return await this.instance.get(url, {...options})
  }

  async post (url, data = {}, options = {}) {
    return this.instance.post(url, data, {...options})
  }

  put (url, data = {}, options = {}) {
    return this.instance.put(url, data, {...options})
  }

  delete (url, options = {}) {
    return this.instance.delete(url, {...options})
  }
}
