<template>
  <div class="wrap">
    <div class="back-link">
      <!-- в карточку можно попасть из двух мест + на прямую -->
      <router-link
        v-if="routeFrom && routeFrom.name === 'attestations'"
        :to="{ name: routeFrom.name }">
        <rir-icon icon="arrow-left"></rir-icon>
        Назад в Аттестуемые
      </router-link>
      <router-link
        v-else
       :to="{ name: 'employees' }">
        <rir-icon icon="arrow-left"></rir-icon>
        Назад к списку сотрудников
      </router-link>
    </div>
    <h1 class="pb-5">
      {{ caption }}
    </h1>

    <!-- <pre>{{ employee }}</pre> -->

    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Личные данные</h3>
      </div>
    </div>

    <div class="row row--no-grep">
      <div
        class="col-md-4-12 col-sm-4-12 col-xs-4-12"
        v-for="(V, K, idx) in {
          lastName: 'Фамилия',
          firstName: 'Имя',
          middleName: 'Отчество',
          position: 'Должность',
          division: 'Отдел',
          department: 'Департамент',
        }"
        :key="idx"
      >
        <rir-input v-model="employee[K]" :label="V" />
      </div>

      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-date-picker v-model="employee.birthDate" label="Дата рождения" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
          v-mask="'+7 (###) ###-##-##'"
          v-model="employee.phone"
          label="Телефон"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
          :error="$v.employee.email.$error"
          :message="mesErr($v.employee.email)"
        >
          <rir-input v-model="employee.email" label="E-mail" />
        </rir-form-item>
      </div>
    </div>

    <!-- START Education -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Образование</h3>
      </div>
    </div>
    <section
      v-for="(education, index) in employee.employeeEducations"
      :key="`education-${index}`"
      class="row row--no-grep"
    >
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12 d-flex align-center">
        <h3 class="clymenti">Учебное заведение {{ index + 1 }}</h3>
        <rir-button
          @click="employee.remEducation(index)"
          icon="delete"
          color="error"
          transparent
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="education.educationType" label="Вид" />
      </div>
      <div class="col-md-8-12 col-sm-8-12 col-xs-8-12">
        <rir-input v-model="education.institution" label="Учебное заведение" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="education.qualification" label="Квалификация" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
            :error="$v.employee.employeeEducations.$each.$iter[index].educationStart.$error"
            :message="mesErr($v.employee.employeeEducations.$each.$iter[index].educationStart)"
        >
          <rir-date-picker
            v-model="education.educationStart"
            label="Начало обучения"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-date-picker
          v-model="education.educationEnd"
          label="Конец обучения"
        />
      </div>
    </section>
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <rir-button color="secondary" @click="employee.addEducation()">
          <rir-icon icon="add" fill="godfather" class="mr-2" />
          Добавить образование
        </rir-button>
      </div>
    </div>
    <!-- END Education -->

    <!-- START Chief -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Руководитель</h3>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employee.chiefLastName" label="Фамилия" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employee.chiefFirstName" label="Имя" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employee.chiefMiddleName" label="Отчество" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
          v-mask="'+7 (###) ###-##-##'"
          v-model="employee.chiefPhone"
          label="Телефон"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
          :error="$v.employee.chiefEmail.$error"
          :message="mesErr($v.employee.chiefEmail)"
        >
          <rir-input v-model="employee.chiefEmail" label="E-mail" />
        </rir-form-item>
      </div>
    </div>
    <!-- END Chief -->

    <!-- START Experience -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Стаж</h3>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
          :error="$v.employee.generalExperience.$error"
          :message="mesErr($v.employee.generalExperience)"
        >
          <rir-input
            type="number"
            v-model="employee.generalExperience"
            label="Общий стаж"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
          :error="$v.employee.municipalExperience.$error"
          :message="mesErr($v.employee.municipalExperience)"
        >
          <rir-input
            type="number"
            v-model="employee.municipalExperience"
            label="Стаж муниципальной службы"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employee.transfer" label="Кадровые перевод" />
      </div>
    </div>
    <!-- END Experience -->

    <!-- START Prev Attestation -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Предыдущие аттестации</h3>
      </div>
    </div>
    <div
      v-for="(attestation, index) in employee.getPrevAttestation()"
      :key="`prev-attestation-${index}`"
      class="row row--no-grep"
    >
      <div class="col-md-3-12">
        <rir-form-item
            :error="$v.employee.attestationInfos.$each.$iter[attestation.index].attestationYear.$error"
            :message="mesErr($v.employee.attestationInfos.$each.$iter[attestation.index].attestationYear)"
        >
          <rir-select
            class="mr-2"
            label="Год аттестации"
            :items="commissionYear(-3)"
            v-model="attestation.attestationYear"
          />
        </rir-form-item>
      </div>
      <div class="col-md-3-12">
        <rir-form-item
            :error="$v.employee.attestationInfos.$each.$iter[attestation.index].month.$error"
            :message="mesErr($v.employee.attestationInfos.$each.$iter[attestation.index].month)"
        >
          <rir-select
            label="Месяц аттестации"
            :items="commissionMonth"
            v-model="attestation.month"
          />
        </rir-form-item>
      </div>
      <div class="col-md-5-12">
        <rir-input v-model="attestation.result" label="Результат аттестации" />
      </div>
      <div class="col-md-1-12 d-flex align-center">
        <rir-button
          @click="deleteAttestation(index)"
          transparent
          icon="delete"
          color="error"
        />
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <rir-button color="secondary" @click="employee.addPrevAttestation()">
          <rir-icon icon="add" fill="godfather" class="mr-2" />
          Добавить предыдущую аттестацию
        </rir-button>
      </div>
    </div>
    <!-- END Prev Attestation -->

    <!-- START Test -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>История тестирования</h3>
      </div>
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <rir-table :headers="tableHeaders" :items="listTest" />
      </div>
    </div>
    <!-- END Test -->

    <!-- START NEXT Attestation -->
    <!-- @TODO Комментария не удалять!!! -->
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Предстоящие аттестации</h3>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12 kleodora anie">
        Изменить месяц и год предстоящей аттестации можно из раздела «Аттестуемые»
      </div>
    </div>
    <div
      class="row row--no-grep">
      <div class="col-md-4-12">
        <rir-form-item
          label="Год аттестации"
        >
          {{ nextAttestation.attestationYear }}
        </rir-form-item>
<!--        <rir-select-->
<!--            class="mr-2"-->
<!--            label="Год аттестации"-->
<!--            :items="commissionYear(0)"-->
<!--            v-model="nextAttestation.attestationYear"-->
<!--        />-->
      </div>
      <div class="col-md-4-12">
        <rir-form-item
            label="Месяц аттестации"
        >
          {{ nextAttestation.getMonthState() }}
        </rir-form-item>
<!--        <rir-select-->
<!--            label="Месяц аттестации"-->
<!--            :items="commissionMonth"-->
<!--            v-model="nextAttestation.month"-->
<!--        />-->
      </div>
      <div class="col-md-4-12">
        <rir-form-item
            label="Статус аттестации"
        >
          {{ nextAttestation.getStateStatus() }}
        </rir-form-item>
<!--        <rir-select-->
<!--            :items="listOfAttestationStatus"-->
<!--            v-model="nextAttestation.state"-->
<!--            @change="changeStatusNextAttestation"-->
<!--            label="Статус аттестации"-->
<!--        />-->
      </div>
    </div>
    <!-- END NEXT Attestation -->

    <div class="row no-padding" v-if="employee.id">
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <h4>Должностная инструкция</h4>
        <br />
        <rir-drag-upload-file
          :count-line="1"
          :count-file="0"
          :upload-url="uploadUrl"
          v-model="listJobFile"
          :acceptFile="['.pdf', '.odt']"
          :params-body="paramsBodyUploadJob"
          :params-headers="paramsHeaderUpload"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <h4>Отзыв</h4>
        <br />
        <rir-drag-upload-file
          :count-line="1"
          :count-file="0"
          :upload-url="uploadUrl"
          v-model="listFeedbackFile"
          :acceptFile="['.pdf', '.odt']"
          :params-body="paramsBodyUploadFeedback"
          :params-headers="paramsHeaderUpload"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <h4>Отчет за период</h4>
        <br />
        <rir-drag-upload-file
          :count-line="1"
          :count-file="0"
          :upload-url="uploadUrl"
          v-model="listReportFile"
          :acceptFile="['.pdf', '.odt']"
          :params-body="paramsBodyUploadReport"
          :params-headers="paramsHeaderUpload"
        />
      </div>
    </div>

    <div class="row no-padding">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <rir-button @click="save()">{{ buttonCaption }}</rir-button>
      </div>
      <div
        class="col-md-12-12 col-sm-12-12 col-xs-12-12"
        v-if="employee.id && !deleting"
      >
        <rir-button color="error" @click="deleting = true"
          >Удалить сотрудника?</rir-button
        >
      </div>
      <div
        class="col-md-6-12 col-sm-6-12 col-xs-6-12"
        v-if="employee.id && deleting"
      >
        <rir-button color="error" @click="deleteEmployee()"
          >Да, удалить</rir-button
        >
      </div>
      <div
        class="col-md-6-12 col-sm-6-12 col-xs-6-12"
        v-if="employee.id && deleting"
      >
        <rir-button @click="deleting = false">Нет, оставить</rir-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import validateMixin from "@/plugins/vuelidate/validateMixin";
import {checkDateStartEnd, minValueNumber, regEx, required} from "@/plugins/vuelidate/customValidate";
import { mask } from "vue-the-mask";

import { EmployeeApi } from "@/api/EmployeeApi";
import { DocumentApi } from "@/api/DocumentApi";
import Employee from "../models/Employee";
import {
  listOfAttestationStatus,
  commissionMonth,
} from "@/models/EmployeeDicts";
import CONFIG from "@/config";
import AttestationInfo from "@/models/AttestationInfo";
import ConfirmDropNextAttestation from "@/components/modal/ConfirmDropNextAttestation";
import ConfirmPassedNextAttestation from "@/components/modal/ConfirmPassedNextAttestation";
import { DateFormatted } from "@/plugins/DateFormatted";
import LinkTestTable from "@/components/table/LinkTestTable";
export default {
  name: "Employee",
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  components: {},
  props: {
    id: {
      type: [String, Number],
      default: () => null,
    },
  },
  beforeRouteEnter(to, from, next) {
    // экземпляр компонента доступен как `vm`
      next(vm => vm.routeFrom = from)
  },

  validations: {
    employee: {
      email: {
        regEx: regEx(
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Не верный формат Email"
        ),
      },
      chiefEmail: {
        regEx: regEx(
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Не верный формат Email"
        ),
      },
      generalExperience: {
        minValueNumber: minValueNumber(0, "Отрицательное число"),
      },
      municipalExperience: {
        minValueNumber: minValueNumber(0, "Отрицательное число"),
      },
      // LSCLOC-323
      employeeEducations: {
        $each: {
          educationStart: {
            checkDateStartEnd: checkDateStartEnd('educationEnd', 'Дата начала больше окончания')
          }
        }
      },
      attestationInfos: {
        $each: {
          attestationYear: {
            required: required('Обязательное поле')
          },
          month: {
            required: required('Обязательное поле')
          }
        }
      }
    },
  },
  data() {
    return {
      routeFrom: null, // Для присвоения на хуке beforeRouteEnter
      commissionMonth: [...commissionMonth],
      employee: new Employee(),
      nextAttestation: new AttestationInfo(),
      listOfMonth: commissionMonth,
      list: [],
      buttonCaption: "Сохранить",
      deleting: false,
      tableHeaders: [
        {
          title: "Название теста",
          column: "name",
        },
        {
          title: "Дата и время",
          column: "startTime",
        },
        {
          title: "Кол-во баллов",
          column: "score",
        },
        {
          title: "Итог тестирования",
          column: "result",
        },
        {
          title: "Код подтверждения",
          column: "surveyCode",
        },
        {
          title: "",
          column: "link",
          component: LinkTestTable,
        },
      ],
    };
  },
  computed: {
    paramsHeaderUpload() {
      return {
        Authorization: "Bearer " + this.$keycloak.token,
      };
    },
    uploadUrl() {
      return `${CONFIG.API_URL}/api/v1/document`;
    },
    paramsBodyUploadJob() {
      return {
        employeeId: this.employee.id,
        type: "JOB_DESCRIPTION",
      };
    },
    paramsBodyUploadFeedback() {
      return {
        employeeId: this.employee.id,
        type: "FEEDBACK",
      };
    },
    paramsBodyUploadReport() {
      return {
        employeeId: this.employee.id,
        type: "REPORT_FOR_THE_PERIOD",
      };
    },
    listJobFile: {
      get() {
        return this.list.filter((el) => el.type === "JOB_DESCRIPTION");
      },
      set(val) {
        this.list = this.checkFiles(val, "JOB_DESCRIPTION");
      },
    },
    listFeedbackFile: {
      get() {
        return this.list.filter((el) => el.type === "FEEDBACK");
      },
      set(val) {
        this.list = this.checkFiles(val, "FEEDBACK");
      },
    },
    listReportFile: {
      get() {
        return this.list.filter((el) => el.type === "REPORT_FOR_THE_PERIOD");
      },
      set(val) {
        this.list = this.checkFiles(val, "REPORT_FOR_THE_PERIOD");
      },
    },
    caption() {
      return this.employee.id
        ? "Редактирование данных сотрудника"
        : "Добавление данных сотрудника";
    },
    commissionYear() {
      return (yearDeviation) => {
        const attestations = this.employee.getPrevAttestation();
        const year = new Date().getFullYear();
        const yearList = [];
        for (let x = yearDeviation; x < 4; x++) {
          yearList.push({
            value: year + x,
            id: year + x,
            disabled: attestations.some((a) => a.attestationYear === year + x),
          });
        }
        return yearList;
      };
    },
    listOfAttestationStatus() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      let disabled = [];
      if (this.nextAttestation.state === "NOT_NEED") {
        disabled = ["PASSED", "EXPIRED"];
        if (
          !this.nextAttestation.month &&
          !this.nextAttestation.attestationYear
        ) {
          disabled.push("WAITING");
        }
      } else if (this.nextAttestation.state === "WAITING") {
        const monthAttestation = commissionMonth.find(
          (e) => e.id === this.nextAttestation.month
        );
        if (
          this.nextAttestation.attestationYear > year ||
          (this.nextAttestation.attestationYear === year &&
            monthAttestation.index > month)
        ) {
          disabled = ["PASSED"];
        }
      }
      return listOfAttestationStatus.map((e) => ({
        ...e,
        disabled: disabled.includes(e.id),
      }));
    },
    listTest() {
      return this.employee.attestationInfos.reduce((test, el) => {
        test = test.concat(
          el.testInfos.map((el) => {
            return {
              ...el,
              startTime: new DateFormatted(el.startTime).ruFormat(),
              result: el.result ? "Пройдено" : "Не пройдено",
            };
          })
        );
        return test;
      }, []);
    },
  },
  mounted() {
    this.loadEmployee();
  },
  methods: {
    async loadEmployee() {
      if (this.id) {
        this.employee = await EmployeeApi.getById(this.id);
        // if (this.employee.chiefId) {
        //   this.chief = await EmployeeApi.getById(this.employee.chiefId);
        // }
        await this.loadFiles();
      } else {
        this.employee = new Employee();
      }
      this.nextAttestation = this.employee.getNextAttestationInfo();
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let res;
      if (this.employee.id) {
        res = await EmployeeApi.update(this.employee.getDataToSave());
      } else {
        res = await EmployeeApi.create(this.employee.getDataToSave());
        if (res && res.data && res.data.id) {
          this.employee.id = res.data.id;
        }
      }
      if (res && res.data && res.data.id) {
        this.$root.$notification({
          type: "success",
          text: 'Данные по сотруднику сохранены!',
        });
        for (const info of this.employee.getPrevAttestation()) {
          EmployeeApi.saveAttestationInfo(
            this.employee.id,
            info.getDataToSave()
          )
          .then((res) => {
            info.id = res.data.id
          })
          .catch((res) => {
            info.month = null
            this.$root.$notification({
              type: "error",
              icon: "warning",
              text: res.message,
            });
          });
        }
      } else {
        this.$root.$notification({
          type: "error",
          text: res.message,
        });
      }
    },
    async loadFiles() {
      const files = await DocumentApi.getList(this.employee.id, null);
      this.list = files.map((f) => {
        f.url = `${CONFIG.API_URL}${f.url}`;
        return f;
      });
    },
    async deleteEmployee() {
      for (const info of this.employee.attestationInfos) {
        await EmployeeApi.deleteAttestationInfo(info.id);
      }
      await EmployeeApi.delete(this.employee.id);
      this.$router.push({ path: `/` });
    },
    remFile(fileId) {
      DocumentApi.delete(fileId);
    },
    checkFiles(arr, type) {
      const files = this.list.filter((el) => el.type === type);
      const filesOther = this.list.filter((el) => el.type !== type);
      if (files.length > arr.length) {
        const fileRem = files.find((f) => {
          return !arr.some((l) => l.id === f.id);
        });
        this.remFile(fileRem.id);
      }
      return [...filesOther, ...this.addHostUrl(arr)]
    },
    // @TODO не удалять!!!
    async changeStatusNextAttestation (type) {
      const oldState = this.nextAttestation.state
      let data, confirm = true
      switch (type.id) {
        case "NOT_NEED":
          confirm = await this.$root.$modal(
            ConfirmDropNextAttestation,
            {},
            { width: "400px" }
          );
          if (confirm) {
            await EmployeeApi.deleteAttestationInfo(this.nextAttestation.id);
            this.nextAttestation = new AttestationInfo();
          }
          break;
        case "PASSED":
          data = await this.$root.$modal(ConfirmPassedNextAttestation, {});
          confirm = data?.confirm;
          if (confirm) {
            this.nextAttestation.state = type.id;
            this.nextAttestation.result = data.resAttestation;
            const res = await EmployeeApi.saveAttestationInfo(
              this.employee.id,
              this.nextAttestation.getDataToSave()
            );
            if (res) {
              !this.nextAttestation.id &&
                this.employee.attestationInfos.push(
                  new AttestationInfo(res.data)
                );
              this.nextAttestation = new AttestationInfo();
            } else {
              this.nextAttestation.state = oldState;
              this.nextAttestation.result = null;
            }
          }
          break;
        default:
          this.nextAttestation.state = type.id;
          data = await EmployeeApi.saveAttestationInfo(
            this.employee.id,
            this.nextAttestation.getDataToSave()
          );
          this.nextAttestation.id = data.data.id;
      }
      if (!confirm) {
        this.nextAttestation.state = oldState;
      }
    },
    deleteAttestation(index) {
      const attestation = this.employee.getPrevAttestation()[index];
      !!attestation.id && EmployeeApi.deleteAttestationInfo(attestation.id);
      this.employee.remPrevAttestation(attestation);
    },
    addHostUrl (val) {
      const host_regex = /(http|https)/
      return val.map(v => {
        if (!host_regex.test(v.url)) {
          v.url = `${CONFIG.API_URL}${v.url}`
        }
        return v
      })
    }
  },
};
</script>
