export const listOfQualification = [
    {id: 'Ведущий специалист', value: 'Ведущий специалист'},
    {id: 'Специалист', value: 'Специалист'},
];

export const listOfEducationType = [
    {id: 'среднее', value: 'среднее'},
    {id: 'высшее', value: 'высшее'},
];

export const listOfAttestationStatus = [
    {id: 'NOT_NEED', value: 'Не требуется'},
    {id: 'WAITING', value: 'Ожидает'},
    {id: 'PASSED', value: 'Пройдено'},
    {id: 'EXPIRED', value: 'Просрочено'},
];

export const listOfCommissionRoles = [
    {id: 'Председатель комиссии', value: 'Председатель комиссии'},
    {id: 'Член комиссии', value: 'Член комиссии'},
    {id: 'Секретарь комиссии', value: 'Секретарь комиссии'},
    {id: 'Заместитель председателя  комиссии', value: 'Заместитель председателя  комиссии'},
];

export const DocumentType = [
    {id: 'ORDER', value: 'Распоряжение'},
    {id: 'PROTOCOL', value: 'Протокол'},
    {id: 'JOB_DESCRIPTION', value: 'Должностная инструкция'},
    {id: 'FEEDBACK', value: 'Отзыв'},
    {id: 'REPORT_FOR_THE_PERIOD', value: 'Отчет'},
    {id: 'ATTESTATION_DECREE', value: 'Постановление'},
];

export const commissionMonth = [
    {id: 'JANUARY', value: 'Январь', index: 0},
    {id: 'FEBRUARY', value: 'Февраль', index: 1},
    {id: 'MARCH', value: 'Март', index: 2},
    {id: 'APRIL', value: 'Апрель', index: 3},
    {id: 'MAY', value: 'Май', index: 4},
    {id: 'JUNE', value: 'Июнь', index: 5},
    {id: 'JULY', value: 'Июль', index: 6},
    {id: 'AUGUST', value: 'Август', index: 7},
    {id: 'SEPTEMBER', value: 'Сентябрь', index: 8},
    {id: 'OCTOBER', value: 'Октябрь', index: 9},
    {id: 'NOVEMBER', value: 'Ноябрь', index: 10},
    {id: 'DECEMBER', value: 'Декабрь', index: 11},
];
