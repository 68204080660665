import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import RirLib from '@/plugins/RirLib'
import App from '@/App.vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import CONFIG from "@/config";

Vue.config.productionTip = false
Vue.use(RirLib);
let VueApp = null
Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false,
    pkceMethod: 'S256'
  },
  config: {
    authRealm: CONFIG.VUE_APP_KC_REALM,
    authUrl: CONFIG.VUE_APP_KC_AUTH,
    authClientId: CONFIG.VUE_APP_KC_UI_CLIENT
  },
  onReady: (keycloak) => {
    if (keycloak.resourceAccess[CONFIG.VUE_APP_KC_ROLE_CLIENT]) {
      VueApp = new Vue({
        router,
        store,
        render: h => h(App),
      }).$mount('#app')

      // refresh Token
      setInterval(() => {
        keycloak.updateToken(550)
      }, 15000)
    } else {
      keycloak.logout()
    }
  }
})
export default VueApp
