<template>
    <div class="menu-links">
        <router-link :to="{name: 'employees'}" class="link">
          <rir-icon
              class="mr-4"
              fill="amelie"
              icon="avatar"
          /> Все сотрудники
        </router-link>
        <router-link :to="{name: 'attestations'}" class="link">
          <rir-icon
              class="mr-4"
              fill="amelie"
              icon="errand"
          /> Аттестуемые
        </router-link>
        <router-link :to="{name: 'documents'}" class="link">
          <rir-icon
              class="mr-4"
              fill="amelie"
              icon="agreement"
          /> Документы
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Menu',
        props: {

        }
    }
</script>

<style lang="scss">
</style>
