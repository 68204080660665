<template>
  <div class="clickable">
    <rir-icon @click.native="goEdit" :icon="item.editing ? 'save' : 'edit'" />
    <!--
    <router-link :to="{ name: 'employee', params: { id: this.item.id } }">
      <rir-icon class="ml-4" icon="reply" />
    </router-link>
    -->
  </div>
</template>

<script>
import { EmployeeApi } from "@/api/EmployeeApi";

export default {
  name: "EmployeeEditButton",
  mounted() {
    this.attestationInfo = this.item.getNextAttestationInfo();
  },
  data: () => ({
    attestationInfo: null,
  }),
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async goEdit() {
      if (this.item.editing) {
        if (
          this.attestationInfo.state === "NOT_NEED" &&
          this.attestationInfo?.id
        ) {
          // Удаление предстоящей аттестации если выставили "Не требуется"
          await EmployeeApi.deleteAttestationInfo(this.attestationInfo.id);
          this.item.editing = false;
        } else if (this.attestationInfo.state !== "NOT_NEED") {
          this.attestationInfo.state = "WAITING";
          try {
            const res = await EmployeeApi.saveAttestationInfo(
              this.item.id,
              this.attestationInfo.getDataToSave()
            );
            this.attestationInfo.id = res.data.id;
            this.item.editing = false;
          } catch (e) {
            this.$root.$notification({
              type: "error",
              icon: "warning",
              text: e.message,
            });
          }
        }
      } else {
        this.item.editing = true;
      }
    },
    selectEmployee() {
      this.$router.push({ name: "employee", params: { id: this.item.id } });
    },
  },
};
</script>

<style scoped>
</style>
