<template>
  <rir-cards-block
      class="py-10">
    <rir-icon
        class="px-10"
        icon="warning"
        fill="fargo"
        size="56"
        slot="status"
    />
    <div slot="title" class="nomira px-10">Внимание</div>
    <section class="d-flex flex-column px-10">
      <span
          class="terminator--text clonia mb-8">
        Информация по предстоящей аттестации, включая результаты тестирования, будет аннулирована
      </span>
      <rir-button
        @click="resConfirm(true)"
      >
        Отменить аттестацию
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
export default {
  name: 'confirm-drop-next-attestation',
  methods: {
    resConfirm (res) {
      this.$attrs.closeModal(res)
    }
  }
}
</script>
