import CommissionMember from "./CommissionMember";

export default class {
    id = null;
    date = null;
    commissionMembers = [];

    constructor(data = {}) {
        for (let key in this) {
            if (data[key] !== undefined) {
                if (data[key] !== null) {
                    this[key] = data[key];
                } else {
                    this[key] = null;
                }
            }
        }
        this.date = this.date || null;

        if (this.commissionMembers) {
            this.commissionMembers = this.commissionMembers.map(el => {
                return new CommissionMember(el);
            });
        }
    }

    getDataToSave() {
        let data = {};
        Object.assign(data, this);
        for (let key in data) {
            if (this[key] === undefined) {
                if (['id'].indexOf(key) !== -1) {
                    // data[key] = null;
                } else {
                    data[key] = null;
                }
            } else if (data[key] === '') {
                data[key] = null;
            }
        }
        // if (data.date) {
        //     data.date = (new DateFormatted(data.date)).Ymd();
        // }
        return data;
    }
}
