<template>
  <div>
    <div class="row no-padding">
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
            v-model="commissionMember.lastName"
            label="Фамилия"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
            v-model="commissionMember.firstName"
            label="Имя"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
            v-model="commissionMember.middleName"
            label="Отчество"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-select
            :items="listOfCommissionRoles"
            v-model="commissionMember.role"
            label="Квалификация"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
            v-model="commissionMember.position"
            label="Должность"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input
            v-model="commissionMember.department"
            label="Департамент"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
            :error="$v.commissionMember.phone.$error"
            :message="mesErr($v.commissionMember.phone)"
        >
          <rir-input
              v-model="commissionMember.phone"
              label="Телефон"
              v-mask="'+7 (###) ###-##-##'"
          />
        </rir-form-item>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-form-item
            :error="$v.commissionMember.email.$error"
            :message="mesErr($v.commissionMember.email)"
        >
          <rir-input
              v-model="commissionMember.email"
              label="Email"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row no-padding">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <rir-button v-if="commissionMember.id" color="error" @click="deleteMember">Удалить члена комиссии</rir-button>
        <rir-button v-if="!commissionMember.id" color="primary" @click="addMember">Добавить члена комиссии</rir-button>
      </div>
    </div>
  </div>
</template>

<script>
import {CommissionApi} from '@/api/CommissionApi';
import {DateFormatted} from "@/plugins/DateFormatted";
import {listOfCommissionRoles} from "@/models/EmployeeDicts";
import CommissionMember from "../models/CommissionMember";
import Commission from "../models/Commission";
import {validationMixin} from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import {regEx, minLength} from '@/plugins/vuelidate/customValidate'
import {mask} from 'vue-the-mask'

export default {
  name: 'CommissionMember',
  directives: {mask},
  mixins: [validationMixin, validateMixin],
  components: {},
  props: {
    commissionMember: {
      type: Object,
      default: () => new CommissionMember()
    },
    commission: {
      type: Object,
      default: () => new Commission()
    }
  },
  data () {
    return {
      listOfCommissionRoles: listOfCommissionRoles,
    }
  },
  mounted () {
    //this.loadCommission();
  },
  validations: {
    commissionMember: {
      email: {
        regEx: regEx(
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Не верный формат Email')
      },
      phone: {
        minLength: minLength(
            18,
            'Не верный формат телефона')
      }
    }
  },
  methods: {
    async addMember () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      let res = await CommissionApi.addMember(this.commission.id, this.commissionMember.getDataToSave())
      if (res && res.data && res.data.id) {
        this.$emit('update:commission', new Commission(res.data));
      }
    },
    async deleteMember () {
      let res = await CommissionApi.deleteMember(this.commission.id, this.commissionMember.id)
      if (res && res.data && res.data.id) {
        this.$emit('update:commission', new Commission(res.data));
      }
    },
    getMonth (date) {
      return (new DateFormatted(date)).month();
    },

  }
}
</script>

<style scoped>

</style>
