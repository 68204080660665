import { helpers } from 'vuelidate/lib/validators'
function checkValue (value) {
  return value !== null && !!value.toString()
}
export const required = (mesErr) =>
  helpers.withParams(
    { type: 'required', mesErr: mesErr },
    (value) => checkValue(value) && helpers.req(value)
  )
export const regEx = (regEx, mesErr) =>
  helpers.withParams(
    { type: 'regEx', mesErr: mesErr },
    (value) => !value || regEx.test(value)
  )
export const typeFloatOrNumber = (mesErr) =>
  helpers.withParams(
    { type: 'typeFloatOrNumber', mesErr: mesErr },
    // eslint-disable-next-line eqeqeq
    (value) => !value || Number(value) == value
  )
export const minLength = (countLength, mesErr) =>
  helpers.withParams(
    { type: 'minLength', mesErr: mesErr },
    (value) => !value || value.length >= countLength
  )
export const strLength = (countLength, mesErr) =>
  helpers.withParams(
    { type: 'minLength', mesErr: mesErr },
    (value) => !!value && value.toString().length === countLength
  )
export const apiCheck = (action, mesErr) =>
  helpers.withParams(
    { type: 'apiCheck', mesErr: mesErr },
    async () => {
      return await action()
    }
  )
export const moreThan = (attribute, mesErr) =>
  helpers.withParams(
    { type: 'moreThan', mesErr: mesErr },
    async (value, obj) => {
      return !value && value >= obj[attribute]
    }
  )
export const lessThan = (attribute, mesErr) =>
  helpers.withParams(
    { type: 'lessThan', mesErr: mesErr },
    (value, obj) => {
      console.log('lessThan', parseInt(value), parseInt(obj[attribute]))
      return parseInt(value) <= obj[attribute]
    }
  )
// LSCLOC-323
export const checkDateStartEnd = (attribute, mesErr) =>
  helpers.withParams(
    { type: 'lessThan', mesErr: mesErr },
    (value, obj) => {
      return !obj[attribute] || new Date(value) < new Date(obj[attribute])
    }
  )
export const minValueNumber = (countValue, mesErr) =>
  helpers.withParams(
    { type: 'minValueNumber', mesErr: mesErr },
    (value) => {
      return !value || parseInt(value) >= countValue
    }
  )
