<template>
  <a :href="href" target="_blank" class="d-flex align-center rocky--text">
    <rir-icon
      class="mr-1"
      icon="link"
    />
    Ссылка на тестирование
  </a>
</template>

<script>
import CONFIG from "@/config";
export default {
  name: 'link-test-table',
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    href () {
      return `${CONFIG.VUE_APP_SURVEY_UI}/test/${this.item.result ? this.item.surveyId : 'code'}`
    }
  }
}
</script>
