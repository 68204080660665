import {DateFormatted} from "@/plugins/DateFormatted";

export default class {
    id = null;
    employeeId = null;
    firstName = null;
    middleName = null;
    lastName = null;
    birthDate = "";
    role = null;
    position = null;
    department = null;
    phone = null;
    email = null;
    registrationDate = null;

    constructor(data = {}) {
        for (let key in this) {
            if (data[key] !== undefined) {
                if (data[key] !== 'null') {
                    this[key] = data[key];
                } else {
                    this[key] = '';
                }
            }
        }
    }

    getDataToSave() {
        let data = {};
        Object.assign(data, this);
        for (let key in data) {
            if (data[key] === undefined || data[key] === null) {
                if (['employeeId', 'id'].indexOf(key) != -1) {
                    // data[key] = null;
                } else {
                    data[key] = 'null';
                }
            } else if (data[key] === '') {
                data[key] = 'null';
            }
        }
        if (data.birthDate) {
            data.birthDate = (new DateFormatted(data.birthDate)).Ymd();
        }
        if (data.registrationDate) {
            data.registrationDate = (new DateFormatted(data.registrationDate)).Ymd();
        }
        return data;
    }
}
