<template>
    <div class="employee-checkbox" @click.stop="clicked()">
        <rir-checkbox v-model="item.checked"/>
    </div>
</template>

<script>
    export default {
        name: 'EmployeeCheckbox',
        props: {
            item: {
                type: Object,
                default: () => {}
            }
        },
        data () {
            return {

            }
        },
        methods: {
            clicked() {

            },
        }
    }
</script>

<style scoped>

</style>
