<template>
  <div class="wrap">
    <h1>
      Аттестуемые
    </h1>
    <Years :attestations.sync="attestations"
           :attestation.sync="attestation"
           @onChangeYear="loadCommissions()"
    ></Years>

    <rir-accordion v-for="(cms, index) in listOfMonth"
                   :key="index"
                   v-bind:class="{ collapsed: cms.collapsed }"

    >
      <div slot="activator" class="d-flex flex-column" @click="cms.collapsed = !cms.collapsed">
        <div class="crephusa titanic--text mb-2">{{ cms.yearMonth }}</div>
        <div class="d-flex align-center">
          <div class="metioche titanic--text ekas mr-4">
            Комиссия {{ getDate(cms.commission.date) }}
          </div>
          <a v-if="cms.commission.id" class="rocky--text amphiris" @click.stop="editCommission(cms.commission.id)">
            <rir-icon class="mb-1" icon="edit"/>
            Редактировать
          </a>
          <a v-if="!cms.commission.id" class="rocky--text amphiris" @click.stop="createCommission(cms.defaultDate)">
            <rir-icon class="mb-1" icon="edit"/>
            Редактировать
          </a>
          <a
              class="d-flex align-center rocky--text amphiris ml-5"
              :href="cms.mailtoEmployees(commissionNotificationEmail, 'commissionsMails')"
              @click.stop="">
            <rir-icon class="mr-1" icon="email"/>
            Оповестить комиссию
          </a>
        </div>
      </div>
      <div class="row row--no-grep">
        <div class="col-md-12-12 col-sm-2-12 col-xs-2-12">
          <a
              class="d-flex align-center rocky--text amphiris"
             :href="cms.mailtoEmployees(employeeNotificationEmail)">
            <rir-icon class="mr-1" icon="email"/>
            Оповестить аттестуемых
          </a>
        </div>
        <div class="col-md-1-12 col-sm-2-12 col-xs-2-12">
          <rir-checkbox @input="cms.selectAllEmployees($event)"/>
        </div>
        <div class="col-md-3-12 col-sm-3-12 col-xs-3-12">
          <rir-select
              label="Месяц аттестации"
              :items="commissionMonth"
              v-model="cms.attestationMonth"
          />
        </div>
        <div class="col-md-3-12 col-sm-3-12 col-xs-3-12">
          <rir-select
              label="Статус"
              :items="listOfAttestationStatus"
              v-model="cms.state"
          />
        </div>
        <div class="col-md-2-12 col-sm-2-12 col-xs-2-12">
          <rir-button v-if="cms.checkedEmployees().length > 0"
                      :disabled="!cms.canApply()"
                      @click="allpyAttestationInfos(cms)"
          >Применить
          </rir-button>
        </div>
      </div>
      <div>
        <rir-table
            :headers="tableHeadres"
            :items="cms.employees"
            :sortList="tableSortList"
            noData="Данных нет"
        />
      </div>
    </rir-accordion>
  </div>
</template>

<script>
import {CommissionApi} from '@/api/CommissionApi';
import {EmployeeApi} from '@/api/EmployeeApi';
import {NotificationApi} from '@/api/NotificationApi';
import Years from './Years';
import EmployeeFio from './EmployeeFio';
import EmployeeYearAttestation from './EmployeeYearAttestation';
import EmployeeYearStatus from './EmployeeYearStatus';
import EmployeeCheckbox from './EmployeeCheckbox';
import EmployeeActionsButtonTable from './attestations/EmployeeActionsButtonTable';
import Attestation from "../models/Attestation";
import ListResponse from "../models/ListResponse";
import CommissionMonth from "../models/CommissionMonth";
import {DateFormatted} from "@/plugins/DateFormatted";
import {commissionMonth, listOfAttestationStatus} from '@/models/EmployeeDicts';
import Commission from "../models/Commission";
import AttestationInfo from "../models/AttestationInfo";

export default {
  name: 'Attestations',
  components: {
    Years,
  },
  props: {},
  data () {
    return {
      employeeNotificationEmail: '',
      commissionNotificationEmail: '',
      attestations: [],
      attestation: new Attestation(),
      attestationInfo: new AttestationInfo(),
      listOfCommission: [],
      listOfMonth: [],
      page: 0,
      size: 10000,
      commissionMonth: commissionMonth,
      tableSortList: [],
      listOfEmployees: new ListResponse(),
      employees: [],
      tableHeadres: [
        {
          "title": "",
          "column": "checkbox",
          "component": EmployeeCheckbox,
          "sort": false
        },
        {
          "title": "№",
          "column": "id",
          "sort": true
        },
        {
          "title": "Аттестуемый",
          "column": "firstName",
          "sort": false,
          "component": EmployeeFio
        },
        {
          "title": "Аттестация",
          "column": "yearAttestation",
          "sort": false,
          "component": EmployeeYearAttestation
        },
        {
          "title": "Статус",
          "column": "status",
          "sort": false,
          "component": EmployeeYearStatus
        },
        {
          "title": "",
          "column": "edit",
          "sort": false,
          "component": EmployeeActionsButtonTable
        }
      ],
    }
  },
  mounted () {
    this.loadCommissions();
    this.loadEmailNotifications();
  },
  computed: {
    listOfAttestationStatus () {
      return listOfAttestationStatus.filter(e => e.id !== 'PASSED')
    }
  },
  methods: {
    async loadEmailNotifications () {
      this.employeeNotificationEmail = await NotificationApi.getEmployeeNotification();
      this.commissionNotificationEmail = await NotificationApi.getCommissionNotification();
    },
    async loadCommissions () {
      this.listOfMonth = [];
      if (this.attestation.id) {
        this.listOfCommission = await CommissionApi.getByAttestationId(this.attestation.id);
      } else {
        this.listOfCommission = [];
      }
      this.listOfEmployees = await EmployeeApi.getListCertified({
        page: this.page,
        size: this.size,
        year: this.attestation.year
      });
      this.employees = this.listOfEmployees.content;
      this.setMonthList();
    },
    setMonthList () {
      let res = [];
      this.commissionMonth.forEach((month, index) => {
        let employeeList = this.employees.filter(emp => {
          return emp.attestationInfos.filter(el => {
            return el.attestationYear == this.attestation.year && el.month == month.id;
          }).length > 0
        });
        let commissionList = this.listOfCommission.filter(el => {
          return (new DateFormatted(el.date)).jsDate.getMonth() == index;
        });
        res.push(
            new CommissionMonth({
              month: month.id,
              yearMonth: month.value + ' ' + this.attestation.year,
              defaultDate: (new Date()).setFullYear(this.attestation.year, index, 1),
              employees: employeeList,
              commission: commissionList.length > 0 ? commissionList[0] : new Commission(),
              attestationMonth: month.id,
            })
        );
      });
      this.listOfMonth = res;
    },
    getMonth (date) {
      return (new DateFormatted(date)).month();
    },
    getDate (date) {
      return date ? (new DateFormatted(date)).dmY() : 'не указана'
    },
    async allpyAttestationInfos (commissionMonth) {
      for (const employee of commissionMonth.checkedEmployees()) {
        let info = employee.getYearAttestationInfo(this.attestation.year);
        info.month = commissionMonth.attestationMonth;
        if (commissionMonth.state) {
          info.state = commissionMonth.state;
        }
        if (commissionMonth.passDate) {
          info.passDate = commissionMonth.passDate;
        }
        EmployeeApi.saveAttestationInfo(employee.id, info.getDataToSave());
        if (info.month != commissionMonth.month) {
          this.moveEmployee(employee, commissionMonth.month, info.month);
        }
      }
    },
    moveEmployee (employee, fromMonth, toMonth, fromYear = this.attestation.year) {
      let fromId, toId;
      this.commissionMonth.forEach((month, index) => {
        if (month.id == fromMonth) {
          fromId = index;
        }
        if (month.id == toMonth) {
          toId = index;
        }
      });
      let indexToDelete = this.listOfMonth[fromId].employees.indexOf(employee);
      if (indexToDelete >= 0) {
        this.listOfMonth[fromId].employees.splice(indexToDelete, 1);
      }
      if (fromYear === this.attestation.year) {
        this.listOfMonth[toId].employees.push(employee);
      }
    },
    remEmployee(employee, fromMonth) {
      let fromId;
      this.commissionMonth.forEach((month, index) => {
        if (month.id == fromMonth) {
          fromId = index;
        }
      });
      let indeToDelete = this.listOfMonth[fromId].employees.indexOf(employee);
      if (indeToDelete >= 0) {
        this.listOfMonth[fromId].employees.splice(indeToDelete, 1);
      }
    },
    editCommission (commissionId) {
      this.$router.push({path: `/commission/` + commissionId});
    },
    async createCommission (commission) {
      let res = await CommissionApi.create(this.attestation.id, new DateFormatted(commission).Ymd());
      if (res && res.data && res.data.id) {
        this.$router.push({path: `/commission/` + res.data.id});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
