import getEnv from "@/plugins/env";
export default {
    API_URL: getEnv('API_URL') || 'https://attestation.bpms.staging.rusatom.dedyn.io',
    VUE_APP_SURVEY_UI: getEnv('VUE_APP_SURVEY_UI') || 'https://survey-ui.bpms.rusatom.dedyn.io/',
    VUE_APP_KC_REALM: getEnv('VUE_APP_KC_REALM') || 'master',
    VUE_APP_KC_AUTH: getEnv('VUE_APP_KC_AUTH') || 'https://keycloak.smartsarov.ru/auth',
    VUE_APP_KC_UI_CLIENT: getEnv('VUE_APP_KC_UI_CLIENT') || 'attestation-ui',
    VUE_APP_KC_ROLE_CLIENT: getEnv('VUE_APP_KC_ROLE_CLIENT') || 'attestation',
    VUE_APP_SENTRY: getEnv('VUE_APP_SENTRY')
};
