<template>
  <div :class="'employee-status ' + state">
        <span v-if="!item.editing">
            {{ status }}
        </span>
    <rir-select
        v-if="item.editing && attestationInfo"
        :items="listOfAttestationStatus"
        v-model="attestationInfo.state"
    />
  </div>
</template>

<script>
import {commissionMonth, listOfAttestationStatus} from "@/models/EmployeeDicts";

export default {
  name: 'EmployeeYearStatus',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      attestationInfo: null,
    }
  },
  mounted () {
    this.attestationInfo = this.item.getYearAttestationInfo(this.attestationYear);
  },
  computed: {
    status () {
      if (this.attestationInfo) {
        return listOfAttestationStatus.find(el => {
          return el.id === this.attestationInfo.state
        })?.value;
      } else {
        return 'нет';
      }
    },
    state () {
      if (this.attestationInfo) {
        return this.attestationInfo.state;
      } else {
        return '';
      }
    },
    attestationYear () {
      // @TODO Переделать в будущем на VUEX
      return this.$parent.$parent.$parent.attestation.year;
    },
    listOfAttestationStatus () {
      const year = (new Date()).getFullYear()
      const month = (new Date()).getMonth()
      const monthAttestation = commissionMonth.find(e => e.id === this.attestationInfo.month)
      let disabled = []
      if (
          this.attestationInfo.attestationYear > year ||
          this.attestationInfo.attestationYear === year &&
          monthAttestation.index > month
      ) {
        disabled = ['PASSED']
      }
      return listOfAttestationStatus.map(e => ({
        ...e,
        disabled: disabled.includes(e.id)
      }))
    }
  }
}
</script>

<style scoped lang="scss">
</style>
