export class DateFormatted {
  jsDate;
  isValidtrue;

  constructor (data) {
    if (data) {
      if ( (data instanceof Date) && data.getTime() && !isNaN(data.getTime())) {
        this.jsDate = data;
      } else if (typeof data == 'string') {
        let matches;
        if ((matches = data.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/))) {
          // дата+время в формате 2020-03-13T14:25:45
          this.jsDate = new Date(matches[0]);
        } else if ((matches = data.match(/^([0-9]{2})\.([0-9]{2})\.([0-9]{4}) ([0-9]{2}):([0-9]{2})/))) {
          // дата в формате 03.12.2020 14:55
          this.jsDate = new Date(matches[3] + '-' + matches[2] + '-' + matches[1] + 'T' + matches[4] + ':' + matches[5] + ':00');
        } else if ((matches = data.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/))) {
          // дата в формате 2020-09-16
          this.jsDate = new Date(matches[1] + '-' + matches[2] + '-' + matches[3] + 'T00:00:00');
        } else if ((matches = data.match(/^([0-9]{2})\.([0-9]{2})\.([0-9]{4})/))) {
          // дата в формате 03.12.2020
          this.jsDate = new Date(matches[3] + '-' + matches[2] + '-' + matches[1]);
        } else {
          // что-то неправильное
          this.isValid = false;
          this.jsDate = new Date();
        }
      } else if (typeof data == 'number') {
        this.jsDate = new Date(data);
      }
    } else {
      this.jsDate = new Date();
    }
  }

  dmY() {
    let y = this.jsDate.getFullYear();
    let m = this.jsDate.getMonth() + 1;
    let d = this.jsDate.getDate();

    return (d<10 ? '0' : '') + d + '.'
      + (m<10 ? '0' : '') + m + '.'
      + y;
  }

  Ymd() {
    let y = this.jsDate.getFullYear();
    let m = this.jsDate.getMonth() + 1;
    let d = this.jsDate.getDate();

    return y + '-'
      + (m<10 ? '0' : '') + m + '-'
      + (d<10 ? '0' : '') + d;
  }

  YmdHHmmss() {
    let y = this.jsDate.getFullYear();
    let m = this.jsDate.getMonth() + 1;
    let d = this.jsDate.getDate();
    let hh = this.jsDate.getHours();
    let mm = this.jsDate.getMinutes();
    let ss = this.jsDate.getSeconds();

    return y + '-'
      + (m<10 ? '0' : '') + m + '-'
      + (d<10 ? '0' : '') + d
      + 'T'
      + (hh<10 ? '0' : '') + hh
      + ':'
      + (mm<10 ? '0' : '') + mm
      + ':'
      + (ss<10 ? '0' : '') + ss
      ;
  }

  ruFormat() {
    let y = this.jsDate.getFullYear();
    let m = this.jsDate.getMonth() + 1;
    let d = this.jsDate.getDate();
    let hh = this.jsDate.getHours();
    let mm = this.jsDate.getMinutes();

    return (d<10 ? '0' : '') + d + '.'
    + (m<10 ? '0' : '') + m + '.'
    + y + ', '
    + (hh<10 ? '0' : '') + hh
    + ':'
    + (mm<10 ? '0' : '') + mm
  }

  HHmm(splitter = ':') {
    let h = this.jsDate.getHours();
    let m = this.jsDate.getMinutes();

    return (h<10 ? '0' : '') + h + splitter + (m<10 ? '0' : '') + m;
  }

  HHmmss(splitter = ':') {
    let h = this.jsDate.getHours();
    let m = this.jsDate.getMinutes();
    let s = this.jsDate.getSeconds();

    return (h<10 ? '0' : '') + h + splitter
           + (m<10 ? '0' : '') + m +splitter
           + (s<10 ? '0' : '') + s;
  }

  dmYHHmm() {
    return this.dmY() + ' ' + this.HHmm(':');
  }

  month(noYear = false) {
      return listOfMonth.filter(el => {
        return el.id == this.jsDate.getMonth() + 1
      })[0].value + ( noYear ? '' : ' ' + this.jsDate.getFullYear() );
  }
}

export const listOfMonth = [
    {id: 1, value: 'Январь'},
    {id: 2, value: 'Февраль'},
    {id: 3, value: 'Март'},
    {id: 4, value: 'Апрель'},
    {id: 5, value: 'Май'},
    {id: 6, value: 'Июнь'},
    {id: 7, value: 'Июль'},
    {id: 8, value: 'Август'},
    {id: 9, value: 'Сентябрь'},
    {id: 10, value: 'Октябрь'},
    {id: 11, value: 'Ноябрь'},
    {id: 12, value: 'Декабрь'},
];
