<template>
  <div class="attestation-year">
    <span
      v-for="(item, index) in attestations"
      @click="selectYear(item)"
      v-bind:class="{ active: attestation.year == item.year }"
      :key="index"
    >
      {{ item.year }}
    </span>
  </div>
</template>

<script>
import { AttestationApi } from "../api/AttestationApi";
import Attestation from "../models/Attestation";

export default {
  name: "Years",
  props: {
    attestations: {
      type: Array,
      default: () => [],
    },
    attestation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.loadAttestationsList();
  },
  methods: {
    async loadAttestationsList() {
      let attestations = await AttestationApi.getList();
      let nextYear = new Date().getFullYear() + 1;
      if (
        attestations.filter((el) => {
          return el.year === nextYear - 1;
        }).length === 0
      ) {
        attestations.push(new Attestation({ year: nextYear - 1 }));
      }
      if (
        attestations.filter((el) => {
          return el.year === nextYear;
        }).length === 0
      ) {
        attestations.push(new Attestation({ year: nextYear }));
      }
      attestations.sort((a, b) => a.year - b.year);
      let currentYearIndex = 0;
      attestations.forEach((el, index) => {
        if (el.year === nextYear - 1) {
          currentYearIndex = index;
        }
      });

      this.$emit("update:attestation", attestations[currentYearIndex]);
      this.$emit("update:attestations", attestations);
      this.$emit("onChangeYear", attestations[currentYearIndex]);
    },
    selectYear(attestation) {
      this.$emit("update:attestation", attestation);
      this.$emit("onChangeYear", attestation);
    },
  },
};
</script>

<style scoped>
</style>
