import RestClient from './RestClient';
import CONFIG from '../config';

let Client = new RestClient({baseURL: CONFIG.API_URL});

export const SurveyApi = {
    async getList() {
        try {
            return await Client.get('/api/v1/survey');
        } catch (e) {
            console.log('error', e);
            return []
        }
    }
};
