export default class {
    id = null;
    year = null;

    constructor(data = {}) {
        for (let key in this) {
            if (data[key] !== undefined) {
              this[key] = data[key];
            }
        }
    }
}
