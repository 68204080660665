import RestClient from './RestClient';
import Employee from '../models/Employee';
import ListResponse from '../models/ListResponse';
import CONFIG from '../config';

let Client = new RestClient({baseURL: CONFIG.API_URL});

export const EmployeeApi = {
  async getList (filter) {
    try {
      let params = new URLSearchParams(Client.getNonEmptyFilter(filter));
      const res = await Client.get('/api/v1/employee?' + params.toString());
      if (res.data && res.data.content) {
        res.data.content = res.data.content.map(el => {
          return new Employee(el);
        });
        return res.data;
      } else {
        console.log('res', res);
        return new ListResponse();
      }
    } catch (e) {
      console.log('error', e);
      return new ListResponse();
    }
  },

  async getListCertified (filter) {
    try {
      let params = new URLSearchParams(Client.getNonEmptyFilter(filter));
      const res = await Client.get('/api/v1/employee/certified?' + params.toString());
      if (res.data && res.data.content) {
        res.data.content = res.data.content.map(el => {
          return new Employee(el);
        });
        return res.data;
      } else {
        console.log('res', res);
        return new ListResponse();
      }
    } catch (e) {
      console.log('error', e);
      return new ListResponse();
    }
  },

  async getById (id) {
    try {
      const res = await Client.get('/api/v1/employee/' + id);
      if (res.data) {
        return new Employee(res.data);
      } else {
        console.log('res', res);
        return new Employee();
      }
    } catch (e) {
      console.log('error', e);
      return new Employee();
    }
  },

  async create (data) {
    try {
      return await Client.post('/api/v1/employee', data);
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async saveAttestationInfo (employeeId, info) {
    const res = await Client.post('/api/v1/employee/' + employeeId + '/attestation-info', info)
    if (res.data) {
      return res
    }
    throw res;
  },

  async deleteAttestationInfo (attestationInfoId) {
    try {
      return await Client.delete('/api/v1/employee/attestation-info/' + attestationInfoId);
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async update (data) {
    try {
      return await Client.put('/api/v1/employee/' + data.id, data);
    } catch (e) {
      return e;
    }
  },

  async delete (id) {
    try {
      return await Client.delete('/api/v1/employee/' + id);
    } catch (e) {
      return e;
    }
  },

  async createTestFromAttestation (attestationInfoId, surveyId) {
    try {
      return  await Client.post(
        `/api/v1/employee/attestation-info/${attestationInfoId}/test-info`,
        { surveyId }
      );
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async sendMailTest (testId) {
    // /api/v1/employee/test-info/{test-info-id}/send-email-with-code
    try {
      await Client.post(
        `/api/v1/employee/test-info/${testId}/send-email-with-code`
      );
      return true
    } catch (e) {
      console.log('error', e);
      return false;
    }
  },

  async update1C () {
    await Client.get(
      'api/v1/internal/employees/import'
    );
  }
};
