<template>
  <rir-cards-block
      class="py-10">
    <div slot="title" class="nomira px-10">Информация о тесте</div>
    <section class="d-flex flex-column px-10">
      <span class="terminator--text clonia mb-1">
        {{ employee.getFio() }}
      </span>
      <span class="titanic--text anie kleodora mb-8">
        {{ employee.position }} {{ employee.department }}
      </span>
      <span class="titanic--text anie kleodora mb-1">
        Тест для прохождения
      </span>
      <span class="titanic--text clymenti mb-6">
        {{ survey.name }}
      </span>
      <span class="titanic--text anie kleodora mb-1">
        Код подтверждения
      </span>
      <span class="rocky--text clymenti mb-8">
        {{ survey.surveyCode }}
      </span>
      <rir-button
        @click="openTest"
      >
        Начать прохождение на этом компьютере
      </rir-button>
      <rir-button
        :loading="isSendMail"
        class="mt-6"
        v-if="isEmail"
        color="secondary"
        @click="sendMailCode"
      >
        Отправить ссылку для прохождения на почту
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import CONFIG from '@/config';
import { EmployeeApi } from '@/api/EmployeeApi'
export default {
  name: 'info-test-modal',
  async mounted () {},
  data: (vm) => ({
    survey: vm.attestation.getTestStart(),
    isSendMail: false
  }),
  props: {
    employee: {
      type: Object
    },
    attestation: {
      type: Object
    }
  },
  computed: {
    isEmail () {
      return !!this.employee.email
    }
  },
  methods: {
    openTest () {
      window.open(
          `${CONFIG.VUE_APP_SURVEY_UI}/test/code`,
          this.survey.name,
          'resizable,scrollbars,status'
      )
    },
    async sendMailCode () {
      this.isSendMail = true
      const res = await EmployeeApi.sendMailTest(this.survey.id)
      res && this.$root.$notification({
        type: 'success',
        icon: 'selected',
        text: 'Письмо отправлено!'
      })
      this.isSendMail = false
    }
  }
}
</script>
