import RestClient from './RestClient';

let Client = new RestClient();

export const NotificationApi = {
    async getEmployeeNotification() {
        try {
            const res = await Client.get('/employeeNotification.txt', {responseType: 'text'});
            if (res.data) {
                return res.data;
            } else {
                return '';
            }
        } catch (e) {
            console.log('error', e);
            return [];
        }
    },

    async getCommissionNotification() {
        try {
            const res = await Client.get('/employeeNotification.txt', {responseType: 'text'});
            if (res.data) {
                return res.data;
            } else {
                return '';
            }
        } catch (e) {
            console.log('error', e);
            return [];
        }
    }
};
