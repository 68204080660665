<template>
  <div class="wrap">
    <div class="back-link">
      <router-link :to="{ name: 'attestations' }">
        <rir-icon icon="arrow-left"></rir-icon> Назад к списку аттестуемых
      </router-link>
    </div>
    <h1>Комиссия на {{ getMonth(commission.date).toLowerCase() }}</h1>
    <br /><br />

    <div class="row no-padding">
      <div
        class="col-md-4-12 col-sm-4-12 col-xs-4-12"
        @change="buttonCaption = 'Сохранить'"
      >
        <rir-date-picker v-model="commission.date" label="Дата комиссии" />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-button @click="saveCommission()">{{ buttonCaption }}</rir-button>
      </div>
    </div>

    <div class="row no-padding">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <h3>Состав</h3>
      </div>
    </div>
    <CommissionMemberCard
      v-for="(member, index) in commission.commissionMembers"
      :key="index"
      :commission-member="member"
      :commission.sync="commission"
    ></CommissionMemberCard>

    <div class="row no-padding">
      <div class="col-md-12-12 col-sm-12-12 col-xs-12-12">
        <div class="light-button full-width" @click="addMemberSlot()">
          <rir-icon icon="add" /> Добавить члена комиссии
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommissionApi } from "../api/CommissionApi";
import { DateFormatted } from "../plugins/DateFormatted";
import Commission from "../models/Commission";
import CommissionMemberCard from "./CommissionMember";
import CommissionMember from "../models/CommissionMember";

export default {
  name: "Commission",
  components: {
    CommissionMemberCard,
  },
  props: {
    id: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      commission: new Commission(),
      buttonCaption: "Сохранить",
    };
  },
  mounted() {
    this.loadCommission();
  },
  methods: {
    async loadCommission() {
      if (this.id) {
        this.commission = await CommissionApi.getByCommisionId(this.id);
      } else {
        this.commission = [];
      }
    },
    async saveCommission() {
      if (this.commission.id) {
        let data = this.commission.getDataToSave();
        this.commission = await CommissionApi.update(this.commission.id, data);
        this.buttonCaption = "Сохранено!";
      }
    },
    getMonth(date) {
      return new DateFormatted(date).month();
    },
    addMemberSlot() {
      this.commission.commissionMembers.push(new CommissionMember());
    },
  },
};
</script>

<style scoped>
</style>
