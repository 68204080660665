<template>
  <router-link
    title="Перейти"
    :to="{ name: 'employee', params: { id: this.item.id } }"
  >
    <b class="itemLastName"
      >{{ item.lastName }} {{ item.firstName }} {{ item.middleName }}</b
    >
    <div class="comment">{{ item.department }} {{ item.position }}</div>
  </router-link>
</template>

<script>
export default {
  name: "EmployeeFio",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>

<style scoped>

.itemLastName:hover {
  color: #5085f1 !important
}

</style>
