<template>
  <rir-cards-block
      class="py-10">
    <div slot="title" class="nomira px-10">
      Результат аттестации
    </div>
    <section class="d-flex flex-column px-10">
      <span
          class="titanic--text metioche ekas mb-6">
        Чтобы перевести статус аттестации в «Пройдено», введите результат аттестации
      </span>
      <rir-input
        class="mb-8"
        label="Результат аттестации"
        v-model="resAttestation"
      />
      <rir-button
        :disabled="!resAttestation"
        @click="resConfirm(true)"
      >
        Завершить аттестацию
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
export default {
  name: 'confirm-passed-next-attestation',
  data: () => ({
    resAttestation: null
  }),
  methods: {
    resConfirm (res) {
      this.$attrs.closeModal({
        confirm: res,
        resAttestation: this.resAttestation
      })
    }
  }
}
</script>
