<template>
  <div class="d-flex align-center justify-end">
    <template v-if="isFinishAttestation">
      <template v-if="!item.editing">
        <rir-button
            v-if="isFinishTest"
            transparent
            @click="openTest"
            icon="application" />
        <rir-button
            transparent
            @click="generateTest"
            icon="send" />
      </template>
      <rir-button
          transparent
          @click="goEdit()"
          :icon="item.editing ? 'save' : 'edit'" />
    </template>
    <rir-button
      transparent
      @click="selectEmployee()"
      icon="reply" />
  </div>
</template>

<script>
import {EmployeeApi} from '@/api/EmployeeApi';
import GenerateTestModal from './GenerateTestModal'
import AttestationInfo from "@/models/AttestationInfo";
import InfoTestModal from "@/components/attestations/InfoTestModal";
import CONFIG from "@/config";
import ConfirmPassedNextAttestation from "@/components/modal/ConfirmPassedNextAttestation";
export default {
  name: 'employee-edit-button-table',
  mounted () {
    this.attestationInfo = this.item.getYearAttestationInfo(this.attestationYear);
    this.attestationOld = { ...this.attestationInfo };
  },
  data: () => ({
    attestationInfo: new AttestationInfo(),
    attestationOld: null,
  }),
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isFinishTest () {
      return !!this.attestationInfo.getFinishTest()
    },
    isFinishAttestation () {
      return this.attestationOld?.state !== 'PASSED'
    },
    isTest () {
      return !!this.attestationInfo.getTestStart()
    },
    attestationYear () {
      // @TODO Переделать в будущем на VUEX
      return this.$parent.$parent.$parent.attestation.year;
    }
  },
  methods: {
    async goEdit () {
      if (this.item.editing) {
        if (this.attestationInfo.state === 'PASSED') {
          const data = await this.$root.$modal(ConfirmPassedNextAttestation, {})
          const confirm = data?.confirm
          if (confirm) {
            this.attestationInfo.result = data.resAttestation
            const model = this.attestationInfo.getDataToSave()
            try {
              await EmployeeApi.saveAttestationInfo(this.item.id, model)
              this.attestationOld = this.attestationInfo
            } catch (e) {
              this.$root.$notification({
                type: 'error',
                icon: 'warning',
                text: e.message
              })
            }
          } else {
            this.attestationInfo.state = this.attestationOld.state
          }
        } else if (this.attestationInfo.state !== 'NOT_NEED') {
          const isChangeDate =
              this.attestationOld.attestationYear !== this.attestationInfo.attestationYear ||
              this.attestationOld.month !== this.attestationInfo.month
          const model = this.attestationInfo.getDataToSave()
          if (isChangeDate) {
            model.state = 'WAITING'
          }
          try {
            await EmployeeApi.saveAttestationInfo(this.item.id, model)
            if (isChangeDate) {
              this.attestationInfo.state = 'WAITING'
              this.$parent.$parent.$parent
                  .moveEmployee(this.item, this.attestationOld.month, this.attestationInfo.month, this.attestationInfo.attestationYear);
            }
            this.item.editing = false;
          } catch (e) {
            this.$root.$notification({
              type: 'error',
              icon: 'warning',
              text: e.message
            })
          }
        } else {
          await EmployeeApi.deleteAttestationInfo(this.attestationInfo.id)
          this.$parent.$parent.$parent.remEmployee(this.item, this.attestationOld.month);
          this.item.editing = false;
        }
      } else {
        this.item.editing = true;
      }
    },
    async generateTest () {
      await this.$root.$modal(this.isTest ? InfoTestModal : GenerateTestModal, {
        employee: this.item,
        attestation: this.attestationInfo
      })
    },
    openTest () {
      const survey = this.attestationInfo.getFinishTest()
      window.open(
          `${CONFIG.VUE_APP_SURVEY_UI}/test/${survey.surveyId}`,
          survey.name,
          'resizable,scrollbars,status'
      )
    },
    selectEmployee () {
      this.$router.push({ name: 'employee', params: {id: this.item.id}});
    },
  }
}
</script>

<style scoped>

</style>
