export default class {
    page = null;
    size = null;
    year = null;
    firstName = null;
    middleName = null;
    lastName = null;
    phone = null;
    department = null;
    attestationState = null;
    fieldName = null;
    order = null;

    // constructor(data = {}) {
    //     for (let key in this) {
    //         if (data[key] !== undefined) {
    //             if (data[key] !== null) {
    //                 this[key] = data[key];
    //             }
    //         }
    //     }
    // }
}
