import RestClient from './RestClient';
import CONFIG from '../config';

let Client = new RestClient({baseURL: CONFIG.API_URL});

export const DocumentApi = {
    async getList(employeeId, attestationId) {
        try {
            let params = {
                employeeId: employeeId,
                attestationId: attestationId
            };
            params = new URLSearchParams(Client.getNonEmptyFilter(params));
            const res = await Client.get('/api/v1/document?' + params.toString());
            if (res.data) {
                return res.data;
            } else {
                console.log('res', res);
                return [];
            }
        } catch (e) {
            console.log('error', e);
            return [];
        }
    },

    async download(id) {
        try {
            const res = await Client.get('/api/v1/document/' + id + '/download');
            if (res.data) {
                return res.data;
            } else {
                console.log('res', res);
                return null;
            }
        } catch (e) {
            console.log('error', e);
            return null;
        }
    },

    async create(employeeId, attestationId, file) {
        try {
            let params = new URLSearchParams({
                employeeId: employeeId,
                attestationId: attestationId
            });
            const res = await Client.post('/api/v1/document?' + params.toString(), file);
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    },

    async delete(id) {
        try {
            const res = await Client.delete('/api/v1/document/' + id);
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    }
};
