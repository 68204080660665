import TestInfo from "@/models/TestInfo";
import {commissionMonth, listOfAttestationStatus} from "@/models/EmployeeDicts";

export default class {
  id = null;
  attestationYear = null;
  month = null;
  state = 'NOT_NEED';
  passDate = null;
  result = null;
  testInfos = [];

  constructor (data = {}) {
    for (let key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key]
      }
    }
    if (this.testInfos) {
      this.testInfos = this.testInfos.map(e => new TestInfo(e))
    }
  }

  getDataToSave () {
    let data = {};
    Object.assign(data, this);
    for (let key in data) {
      if (this[key] === undefined || data[key] === null) {
        if (['chiefId', 'id', 'passDate'].indexOf(key) !== -1) {
          // data[key] = null;
        } else {
          data[key] = null;
        }
      } else if (this[key] === '') {
        data[key] = null;
      }
    }
    // if (data.passDate) {
    //     data.passDate = (new DateFormatted(data.passDate)).YmdHHmmss();
    // }
    return data;
  }

  // Me

  getFinishTest () {
    return this.testInfos
      .find(e => {
        return !!e.finishTime
      }) || null
  }

  getTestStart () {
    return this.testInfos
      .find(e => {
        return !e.finishTime
      }) || null
  }

  getStateStatus () {
    return listOfAttestationStatus.find(l => l.id === this.state)?.value || ''
  }

  getMonthState () {
    return commissionMonth.find(l => l.id === this.month)?.value || ''
  }

}
