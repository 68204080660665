<template>
  <div class="wrap page-employees">
    <div class="d-flex align-center mb-5">
      <h1 class="mr-5">Все сотрудники</h1>
      <rir-button
          :loading="isLoad1C"
          @click="update1C"
          content-width>Обновить с 1С</rir-button>
    </div>
    <Years
      :attestations.sync="attestations"
      :attestation.sync="attestation"
      class="isHidden"
    ></Years>
    <div class="row row--no-grep">
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employeeFilter.lastName" label="Поиск по фамилии">
          <rir-icon slot="before" icon="search" />
        </rir-input>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-button @click="loadEmployees"> Применить фильтр </rir-button>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-input v-model="employeeFilter.department" label="Департамент">
        </rir-input>
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-select
          :items="prevAttestationYears"
          v-model="employeeFilter.year"
          label="Год предыдущей аттестации"
          select-all
          @change="changeSelectFilter('YEAR')"
        />
      </div>
      <div class="col-md-4-12 col-sm-4-12 col-xs-4-12">
        <rir-select
          :items="listOfAttestationStatus"
          v-model="employeeFilter.attestationState"
          label="Назначенная аттестация"
          @change="changeSelectFilter('STATUS')"
        />
      </div>
    </div>

    <rir-table
      :headers="tableHeadres"
      :items="listOfEmployees.content"
      :sortList="tableSortList"
      noData="Данных нет"
      single-sort
      v-on:update:sortList="setSorting($event)"
    />
    <!-- TODO pagination | done SAH-516: не делать лишних запросов -->
    <div class="pagination" v-if="listOfEmployees.totalPages > 1">
      <span
        >Показано {{ listOfEmployees.content.length }} из
        {{ listOfEmployees.totalElements }}</span
      >
      <a title="Первая страница" class="toStart" @click="page && setPage(0)">
        <rir-icon icon="rewind" />
      </a>
      <a title="Назад" class="back" @click="page && setPage(page - 1)">
        <rir-icon icon="arrow-left" />
      </a>
      <span>{{ page + 1 }} из {{ listOfEmployees.totalPages }}</span>
      <a
        title="Вперед"
        class="next"
        :sisabled="true"
        @click="page + 1 < listOfEmployees.totalPages && setPage(page + 1)"
      >
        <rir-icon icon="arrow-right" />
      </a>
      <a
        title="Последняя страница"
        class="toEnd"
        @click="
          page + 1 < listOfEmployees.totalPages &&
            setPage(listOfEmployees.totalPages - 1)
        "
      >
        <rir-icon icon="forward" />
      </a>
    </div>
    <div class="add-button" @click="addEmployee()">
      <rir-icon fill="amelie" icon="add" />
    </div>
  </div>
</template>

<script>
import { EmployeeApi } from "@/api/EmployeeApi";
import ListResponse from "../models/ListResponse";
import Years from "./Years";
import EmployeeFio from "./EmployeeFio";
import EmployeeEditButton from "./EmployeeEditButton";
import EmployeePrevAttestation from "./EmployeePrevAttestation";
import EmployeeNextAttestation from "./EmployeeNextAttestation";
import Attestation from "../models/Attestation";
import EmployeeFilter from "@/models/EmployeeFilter";
import { listOfAttestationStatus } from "@/models/EmployeeDicts";

export default {
  name: "Employees",
  components: {
    Years,
  },
  data() {
    return {
      isLoad1C: false,
      attestations: [],
      attestation: new Attestation(),
      employeeFilter: new EmployeeFilter(),
      listOfAttestationYears: [],
      page: 0,
      size: 20,
      listOfEmployees: new ListResponse(),
      searchQuery: "",
      tableSortList: [
        {
          column: "id",
          direction: "ASC",
        },
      ],
      tableHeadres: [
        {
          title: "№",
          column: "id",
          width: "45px",
          sort: true,
        },
        {
          title: "Аттестуемый",
          column: "firstName",
          sort: false,
          component: EmployeeFio,
        },
        // {
        //   "title": "Департамент",
        //   "column": "department",
        //   width: "86px",
        //   "sort": false
        // },
        {
          title: "Предыдущая",
          column: "year",
          width: "92px",
          sort: true,
          component: EmployeePrevAttestation,
        },
        {
          title: "Назначенная аттестация",
          column: "assignedAttestation",
          sort: true,
          component: EmployeeNextAttestation,
        },
        {
          title: "",
          column: "edit",
          sort: false,
          component: EmployeeEditButton,
        },
      ],
    };
  },
  computed: {
    prevAttestationYears() {
      const currentYear = new Date().getFullYear();
      return this.attestations
        .filter((el) => {
          return el.year <= currentYear;
        })
        .map((el) => {
          return {
            id: el.year,
            value: el.year,
          };
        });
    },
    listOfAttestationStatus() {
      return [
        { id: null, value: "Все статусы" },
        ...listOfAttestationStatus.filter((e) => e.id !== "PASSED"),
      ];
    },
  },
  mounted() {
    this.loadEmployees();
  },
  methods: {
    async loadEmployees() {
      this.employeeFilter.page = this.page;
      this.employeeFilter.size = this.size;
      if (this.tableSortList.length > 0) {
        this.employeeFilter.fieldName = this.tableSortList[0].column;
        this.employeeFilter.order = this.tableSortList[0].direction;
      } else {
        this.employeeFilter.fieldName = null;
        this.employeeFilter.order = null;
      }
      this.listOfEmployees = await EmployeeApi.getList({
        ...this.employeeFilter,
      });
    },
    setPage(page) {
      if (page <= 0) {
        this.page = 0;
      } else if (page >= this.listOfEmployees.totalPages) {
        this.page = this.listOfEmployees.totalPages;
      } else {
        this.page = page;
      }
      this.loadEmployees();
    },
    setSorting(event) {
      if (event.length <= 1) {
        this.tableSortList = event;
      } else {
        const usedCols = this.tableSortList.map((el) => {
          return el.column;
        });
        this.tableSortList = event.filter((el) => {
          return usedCols.indexOf(el.column) === -1;
        });
      }
      this.loadEmployees();
    },
    addEmployee() {
      this.$router.push({ path: `/employee/add/` });
    },
    changeSelectFilter(type) {
      switch (type) {
        case "YEAR":
          this.employeeFilter.attestationState = null;
          break;
        case "STATUS":
          this.employeeFilter.year = null;
          break;
      }
    },
    async update1C () {
      try {
        this.isLoad1C = true
        await EmployeeApi.update1C()
      } catch (e) {
        this.$root.$notification({
          type: "error",
          text: e.message,
        })
      }
      this.isLoad1C = false
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
