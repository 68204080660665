export default class {
  id = null;
  educationType = null;
  institution = null;
  qualification = null;
  educationStart = null;
  educationEnd = null;

  constructor(data = {}) {
    for (let key in this) {
      if (data[key] !== undefined) {
        if (data[key] !== null) {
          this[key] = data[key];
        }
      }
    }
  }
}
