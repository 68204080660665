<template>
  <div class="employee-prev-attestation">
    {{ year }}
  </div>
</template>

<script>
export default {
  name: 'EmployeePrevAttestation',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    year () {
      return (this.item.getPrevAttestationInfo()) ?
          this.item.getPrevAttestationInfo().attestationYear :
          'нет';
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
