import {DateFormatted} from "@/plugins/DateFormatted";
import AttestationInfo from "./AttestationInfo";
import EducationInfo from "@/models/EducationInfo";

export default class {
  editing = false;
  checked = false;
  newEntityAttestation = new AttestationInfo();
  fio = "";

  id = null;
  division = null;
  department = null;
  position = null;
  firstName = null;
  middleName = null;
  lastName = null;
  birthDate = null;
  phone = null;
  email = null;
  educationType = null;
  institution = null;
  educationStart = null;
  educationEnd = null;
  qualification = null;
  generalExperience = null;
  municipalExperience = null;
  transfer = null;
  attestationInfos = [];
  employeeEducations = [];

  // Chief Fields
  chiefFirstName = null
  chiefLastName = null
  chiefMiddleName = null
  chiefPhone = null
  chiefEmail = null

  constructor (data = {}) {
    for (let key in this) {
      if (data[key] !== undefined) {
        if (data[key] !== null) {
          this[key] = data[key];
        }
      }
    }
    this.birthDate = this.birthDate ? (new DateFormatted(this.birthDate)).jsDate.getTime() : null;
    this.educationStart = this.educationStart ? (new DateFormatted(this.educationStart)).jsDate.getTime() : null;
    this.educationEnd = this.educationEnd ? (new DateFormatted(this.educationEnd)).jsDate.getTime() : null;
    if (this.attestationInfos) {
      this.attestationInfos = this.attestationInfos
        .sort((a, b) => a.attestationYear - b.attestationYear)
        .map(el => {
        return new AttestationInfo(el);
      });
    }
    if (this.employeeEducations) {
      this.employeeEducations = this.employeeEducations.map(el => {
        return new EducationInfo(el);
      });
    }
    this.fio = this.getFio();
  }

  getFio () {
    return [this.lastName, this.firstName, this.middleName].join(' ');
  }

  getPrevAttestationInfo () {
    const attestation = this.attestationInfos
      .filter(e => e.state === 'PASSED')
      .reduce(function(prev, current) {
        return (prev.attestationYear > current.attestationYear) ? prev : current
      }, {
        attestationYear: 0
      })
    if (attestation.attestationYear) {
      return attestation;
    } else {
      return null;
    }
  }

  getYearAttestationInfo (year) {
    let index = null;
    for (let i in this.attestationInfos) {
      if ((this.attestationInfos[i].attestationYear === year)) {
        index = i;
        break;
      }
    }
    if ((index != null) && this.attestationInfos[index]) {
      return this.attestationInfos[index];
    } else {
      return new AttestationInfo({
        attestationYear: year
      });
    }
  }

  getCurrentAttestationInfo () {
    let year = (new Date()).getFullYear();
    let index = null;
    for (let i in this.attestationInfos) {
      if ((this.attestationInfos[i].attestationYear === year)) {
        // прошлые года - прошедшая
        index = i;
        break;
      }
    }
    if ((index != null) && this.attestationInfos[index]) {
      return this.attestationInfos[index];
    } else {
      return null;
    }
  }

  addCurrentAttestationInfo () {
    this.attestationInfos.push(new AttestationInfo({
      attestationYear: (new Date()).getFullYear()
    }));
  }

  addNextAttestationInfo () {
    if (this.getNextAttestationInfo()) {
      return;
    }
    this.attestationInfos.push(new AttestationInfo({
      attestationYear: (new Date()).getFullYear() + 1
    }));
  }

  removeNextAttestationInfo () {
    this.getNextAttestationInfo().state = 'NOT_NEED';
  }

  getDataToSave () {
    let data = {};
    Object.assign(data, this);
    delete data.editing;
    delete data.checked;
    delete data.newEntityAttestation;
    delete data.fio;
    for (let key in data) {
      if (this[key] === undefined || data[key] === null) {
        if (['chiefId', 'id'].indexOf(key) !== -1) {
          // data[key] = null;
        } else {
          data[key] = null;
        }
      } else if (this[key] === '') {
        data[key] = null;
      }
    }
    if (data.birthDate) {
      data.birthDate = (new DateFormatted(data.birthDate)).Ymd();
    }
    data.attestationInfos = data.attestationInfos.map(el => {
      return (new AttestationInfo(el)).getDataToSave();
    });
    data.employeeEducations = data.employeeEducations.map(el => {
      return new EducationInfo(el);
    });
    return data;
  }

  // My Property
  addPrevAttestation () {
    const attestation = new AttestationInfo({
      state: 'PASSED'
    })
    this.attestationInfos.push(attestation)
  }
  remPrevAttestation (attestation) {
    const indexAttestation = this.attestationInfos.findIndex(el => JSON.stringify(el) === JSON.stringify(attestation))
    this.attestationInfos.splice(indexAttestation, 1)
  }
  getPrevAttestation () {
    return this.attestationInfos
      .map((a, index) => (Object.assign(a, { index })))
      .filter(a => {
      return a.state === 'PASSED'
    })
  }

  getNextAttestationInfo () {
    const attestation = this.attestationInfos
      .filter(e => ['EXPIRED', 'WAITING'].includes(e.state))
      .reduce(function(prev, current) {
        return (prev.attestationYear > current.attestationYear) ? prev : current
      }, {
        attestationYear: 0
      })
    if (attestation.attestationYear) {
      return attestation;
    } else {
      return this.newEntityAttestation;
    }
  }

  addEducation () {
    this.employeeEducations.push(new EducationInfo())
  }
  remEducation (index) {
    this.employeeEducations.splice(index, 1)
  }
}
