<template>
  <rir-cards-block
      class="py-10">
    <div slot="title" class="nomira px-10">Выбор теста</div>
    <section class="d-flex flex-column px-10">
      <span class="terminator--text clonia mb-1">
        {{ employee.getFio() }}
      </span>
      <span class="titanic--text anie kleodora mb-8">
        {{ employee.position }} {{ employee.department }}
      </span>
      <rir-select
        class="mb-8"
        :items="listSurvey"
        v-model="surveyId"
        text-value="name"
        label="Тест для прохождения"
        />
      <rir-button
          :disabled="!surveyId"
          @click="createTest()"
      >
        Сформировать код подтверждения
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import { SurveyApi } from '@/api/SurveyApi'
import { EmployeeApi } from '@/api/EmployeeApi'
import InfoTestModal from './InfoTestModal'
import TestInfo from "@/models/TestInfo";
export default {
  name: 'generate-test-modal',
  async mounted () {
    const res = await SurveyApi.getList()
    this.listSurvey = res.data
  },
  data: () => ({
    listSurvey: [],
    surveyId: null
  }),
  props: {
    employee: {
      type: Object
    },
    attestation: {
      type: Object
    }
  },
  methods: {
    async createTest () {
      const { data } = await EmployeeApi.createTestFromAttestation(this.attestation.id, this.surveyId)
      console.log('createTest', data)
      this.attestation.testInfos.push(new TestInfo(data))
      this.$attrs.closeModal()
      this.$root.$modal(InfoTestModal, this.$props)
    }
  }
}
</script>
