import Vue from 'vue'
import VueRouter from 'vue-router';
import Employees from './components/Employees'
import Employee from './components/Employee'
import Attestations from './components/Attestations'
import Documents from './components/Documents'
import Commission from './components/Commission'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'employees',
      component: Employees
    },
    {
      path: '/employee/add',
      name: 'employeeAdd',
      component: Employee,
    },
    {
      path: '/employee/:id',
      name: 'employee',
      component: Employee,
      props: true
    },
    {
      path: '/attestations',
      name: 'attestations',
      component: Attestations
    },
    {
      path: '/commission/:id',
      name: 'commission',
      component: Commission,
      props: true
    },
    {
      path: '/documents',
      name: 'documents',
      component: Documents
    }
  ]
});

export default router;
