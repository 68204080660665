<template>
    <span v-if="!item.editing">
        {{ isAttestationNext ? `${attestationInfo.attestationYear} ${month}` : month }}
    </span>
  <div class="d-flex align-center" v-else>
    <rir-select
        style="width: 96px"
        class="mr-2"
        label="год"
        :items="commissionYear(0)"
        v-model="attestationInfo.attestationYear"
    />
    <rir-select
        label="месяц"
        style="width: 132px"
        :items="commissionMonth"
        v-model="attestationMonth"
        @change="changeMonth"
    />
  </div>
</template>

<script>
import {commissionMonth} from "@/models/EmployeeDicts";
import {EmployeeApi} from "@/api/EmployeeApi";
// import {DateFormatted} from "../plugins/DateFormatted";

export default {
  name: 'EmployeeNextAttestation',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data: ()  => ({
    commissionMonth: [{id: 'NOT_NEED', value: 'Не требуется'}, ...commissionMonth],
    attestationMonth: 'NOT_NEED',
    attestationYear: null,
    attestationInfo: null,
  }),
  mounted () {
    this.attestationInfo = this.item.getNextAttestationInfo();
    if (['EXPIRED', 'WAITING'].includes(this.attestationInfo.state)) {
      this.attestationYear = this.attestationInfo.attestationYear
    } else {
      this.attestationYear = (new Date()).getFullYear() + 1
    }
    if (this.attestationInfo.state === 'NOT_NEED') {
      this.attestationMonth = 'NOT_NEED';
    } else {
      this.attestationMonth = this.attestationInfo.month;
    }
  },
  computed: {
    month () {
      return this.commissionMonth.find(el => {
            return el.id === this.attestationMonth;
          }).value
    },
    isAttestationNext () {
      return this.attestationMonth !== 'NOT_NEED'
    },
    commissionYear () {
      return (yearDeviation) => {
        const attestations = this.item.getPrevAttestation()
        const year = (new Date()).getFullYear()
        const yearList = []
        for (let x = yearDeviation; x < 4; x++) {
          yearList.push({
            value: year + x,
            id: year + x,
            disabled: attestations.some(a => a.attestationYear === year + x)
          })
        }
        return yearList
      }
    },
  },
  methods: {
    async save () {
      if (this.attestationMonth === 'NOT_NEED' && this.attestationInfo?.id) {
        // Удаление предстоящей аттестации если выставили "Не требуется"
        await EmployeeApi.deleteAttestationInfo(this.attestationInfo.id)
        this.attestationMonth = 'NOT_NEED';
        this.item.editing = false;
      } else if (this.attestationMonth !== 'NOT_NEED') {
        this.attestationInfo.state = 'WAITING';
        this.attestationInfo.month = this.attestationMonth;
        this.attestationInfo.attestationYear = this.attestationYear;
        try {
          const res = await EmployeeApi.saveAttestationInfo(this.item.id, this.attestationInfo.getDataToSave())
          this.attestationInfo.id = res.data.id
          this.item.editing = false;
        } catch (e) {
          this.item.editing = true
          this.$root.$notification({
            type: 'error',
            icon: 'warning',
            text: e.message
          })
        }
      }
    },
    changeMonth (type) {
      if (type.id === 'NOT_NEED') {
        this.attestationInfo.state = 'NOT_NEED'
      } else {
        this.attestationInfo.month = type.id
        this.attestationInfo.state = 'WAITING'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.employee-next-attestation {
  position: relative;

  .select-month {
    padding-bottom: 5px;
  }

  .select-date {
    display: inline-block;
    vert-align: top;
  }

  .save {
    display: block;
    position: absolute;
    left: -35px;
    bottom: 0;
    padding: 10px;
    cursor: pointer;
  }
}
</style>
