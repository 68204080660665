import * as FileSaver from 'file-saver';
import CONFIG from '../config';


// import axios from 'axios'
import RestClient from './RestClient';

/*
let Client = axios.create({
  baseURL: CONFIG.API_URL || ''
});
const headers = {
  'Authorization': 'Bearer ' + sessionStorage.getItem('attestation-token'),
}
*/


let Client = new RestClient({ baseURL: CONFIG.API_URL });


export const ReportApi = {

  getFileName (response, defaulName = 'file.odt') {
    let matches;
    if (response.headers && response.headers['content-disposition']
      && (matches = response.headers['content-disposition'].match(/(?<=filename=")[^"]+/))) {
      return decodeURI(matches[0].replaceAll('+', ' '));
    }
    return defaulName;
  },

  async getAttestationDecree (year) {
    try {
      let body = {
        documentType: "ATTESTATION_DECREE",
        year: year
      };
      let response = await Client.post(`/api/v1/report`, body, {
        responseType: 'blob',
        // headers
      });
      FileSaver.saveAs(response.data, this.getFileName(response));
      return true;
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async getOrderReport (commissionDate) {
    try {
      let body = {
        documentType: "ORDER",
        commissionDate: commissionDate
      };
      let response = await Client.post(`/api/v1/report`, body, {
        responseType: 'blob',
        // headers
      });
      FileSaver.saveAs(response.data, this.getFileName(response));
      return true;
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async getCommissionProtocol (commissionId) {
    try {
      let body = {
        documentType: "PROTOCOL",
        commissionId: commissionId
      };
      let response = await Client.post(`/api/v1/report`, body, {
        responseType: 'blob',
        // headers
      });
      FileSaver.saveAs(response.data, this.getFileName(response));
      return true;
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

  async getCertificationSheet (commissionId) {
    try {
      let body = {
        documentType: "CERTIFICATION_SHEET",
        commissionId: commissionId
      };
      let response = await Client.post(`/api/v1/report`, body, {
        responseType: 'blob',
        // headers
      });
      FileSaver.saveAs(response.data, this.getFileName(response));
      return true;
    } catch (e) {
      console.log('error', e);
      return e;
    }
  },

};
