import RestClient from './RestClient';
import CONFIG from '../config';
import Commission from "../models/Commission";

let Client = new RestClient({baseURL: CONFIG.API_URL});

export const CommissionApi = {
    async getByAttestationId(attestationId) {
        try {
            const res = await Client.get('/api/v1/commission?attestationId=' + attestationId);
            if (res.data) {
                return res.data.map(el => {
                    return new Commission(el);
                });
            } else {
                console.log('res', res);
                return [];
            }
        } catch (e) {
            console.log('error', e);
            return [];
        }
    },

    async getByCommisionId(comissionId) {
        try {
            const res = await Client.get('/api/v1/commission?commissionId=' + comissionId);
            if (res.data && res.data[0]) {
                return new Commission(res.data[0]);
            } else {
                console.log('res', res);
                return new Commission();
            }
        } catch (e) {
            console.log('error', e);
            return new Commission();
        }
    },

    async create(attestationId, commissionDate) {
        try {
            let params = new URLSearchParams({
                attestationId: attestationId,
                commissionDate: commissionDate
            });
            const res = await Client.post('/api/v1/commission?' + params.toString());
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    },

    async update(commissionId, commission) {
        try {
            console.log('update commission', commissionId, commission);
            const res = await Client.put('/api/v1/commission/' + commissionId, commission);
            if (res && res.data) {
                return new Commission(res.data);
            } else {
                console.log('res', res);
                return new Commission();
            }
        } catch (e) {
            console.log('error', e);
            return e;
        }
    },

    async addMember(commissionId, commissionMember) {
        try {
            const res = await Client.post('/api/v1/commission/' + commissionId + '/member', commissionMember);
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    },

    async deleteMember(commissionId, memberId) {
        try {
            const res = await Client.delete('/api/v1/commission/' + commissionId + '/member/' + memberId);
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    },

    async delete(commissionId) {
        try {
            const res = await Client.delete('/api/v1/commission/' + commissionId);
            return res;
        } catch (e) {
            console.log('error', e);
            return e;
        }
    }

};
